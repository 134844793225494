
class UtilityFunctions {
    updateKeysWithFileName(dataArray) {
        return dataArray.map(obj => {
          const updatedObj = {};
          const filePath = obj['Key'];
          const parts = filePath.split('/');
          const fileNameWithExtension = parts.pop();
          const fileName = fileNameWithExtension.split('.')[0];
          updatedObj['Key'] = fileName;
          updatedObj['Content'] = obj['Content'];
          return updatedObj;
        });
    };

    getKeyAndContentOptions(dataArray) {
      let dataWithUpdatedKeys = this.updateKeysWithFileName(dataArray);
      return dataWithUpdatedKeys.map((d)=>{
          return {
            title:  d.Key,
            values: JSON.parse(d.Content)
          };
        });
  };


    getKeyAndContent(dataArray) {
        let dataWithUpdatedKeys = this.updateKeysWithFileName(dataArray);
        return dataWithUpdatedKeys.map((d)=>{
            return {
              title:  d.Key,
              fields: JSON.parse(d.Content).fields
            };
          });
    };

    getKeyAndContentFields(dataArray) {
      let dataWithUpdatedKeys = this.updateKeysWithFileName(dataArray);
      return dataWithUpdatedKeys.map((d)=>{
          return {
            name:  d.Key,
            field: JSON.parse(d.Content)
          };
        });
  };


    getKeyAndContentForms(dataArray) {
      let dataWithUpdatedKeys = this.updateKeysWithFileName(dataArray);
      return dataWithUpdatedKeys.map((d)=>{
          return {
            key: d.Key,
            formHeaders: JSON.parse(d.Content)['formHeaders'] ,
            sections: JSON.parse(d.Content)['sections'],
            submitApi: JSON.parse(d.Content)['submitApi']
          };
        });
  };

  getKeyAndContentFormsDynamoDb(dataArray) {
    let dataWithUpdatedKeys = this.updateKeysWithFileName(dataArray);
    return dataWithUpdatedKeys.map((d)=>{
        return {
          key: d.Key,
          formHeaders: JSON.parse(d.Content)['formHeaders'] ,
          sections: JSON.parse(d.Content)['sections'],
          submitApi: JSON.parse(d.Content)['submitApi']
        };
      });
};


  checkIfOnlyReadAccess(user){
    const userGroupName  = user?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    if(userGroupName.includes('reader')){
      console.log('Settings is only reader to true: ');
      return true;
    };
    return false;
  };

  checkAdminAccess(user){
    const userGroupName  = user?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    if(userGroupName.includes('admin')){
      console.log('Setting is admin true: ');
      return false;
    };
    return true;
  };

  getPrimaryUserGroup (user){
    const userGroups  = user?.signInUserSession?.accessToken?.payload["cognito:groups"].filter((g)=>g!='activeSubscription');
    return userGroups[0];
  };

  isEven(number) {
    return number % 2 === 0;
  };


  validateUploadedOptionsJson(template, jsonArray) {
    // Check if input is an array
    if (!Array.isArray(jsonArray)) {
        return false;
    }

    // Iterate over each item in the array
    for (const item of jsonArray) {
        // Check if each item is an object
        if (typeof item !== "object" || item === null) {
            return false;
        }

        // Check if each key in the template exists and has the correct type
        for (const key in template) {
            // debugger
            const expectedType = typeof template[key];
            const actualType = item[key] === null ? "object" : typeof item[key];

            // If the key doesn't exist or the type doesn't match, return false
            if (!item.hasOwnProperty(key) || actualType !== expectedType) {
                return false;
            }
        }

        // Check if there are extra keys in the item that are not in the template
        for (const key in item) {
            if (!template.hasOwnProperty(key)) {
                return false;
            }
        }
    }

    // If all items pass the validation, return true
    return true;
}

base64ToBlob(base64, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64); // Decode the base64 string to get binary data
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

}

const utilityFunctions = new UtilityFunctions();
export default utilityFunctions;


