import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box , Avatar, useMediaQuery, Grid, MenuItem,IconButton, Menu} from '@mui/material';
import logo from './citra-header-logo.png';
import pic from './builder.svg';
import { useNavigate } from 'react-router-dom';
import classes from './LandingPage.module.css';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
// import logoNew from '../SideMenu/FreeForm-Logo.png';
import logoNew from '../../common/images/FreeForm-Logo_Bold.png';
import localStorageService from '../../common/services/localStorage.service';

const LandingPageMUI = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const matches = useMediaQuery('(max-width:920px)');

  useEffect(()=>{
    const user = localStorageService.getObject('user');
    console.log("currentCognito User: ", user)
    if(user !== null) {
        console.log("Authentication Successfull. Navigating to side menu.");
        navigate('/sideMenu');
    }
  },[]);

  const handleLogin = () => {
    navigate('/signIn');
  };

  const handleSignUp = () => {
    navigate('/signUpType');
  };


  const handlePrivacyButton = () => {

  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const isSmallScreen = useMediaQuery('(min-width:600px)');

  return (
    <div>
      <AppBar position="fixed" color="inherit" elevation={0} sx={{ backgroundColor: '#f0f0f0', color: '#333' }}>
        <Toolbar  sx={{ color: '#2b4252'}} >
        <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-25px', marginRight: '100px', width: '180px', height: '65px' }} />
        {/* <Avatar src={logo} alt="Logo" sx={{ marginRight: '10px',width: '40px', height: '30px' }} />
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Freeform.IO
          </Typography> */}
          {
            matches &&
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: '10px', right: 0, padding: 0, position: 'fixed'}}>
              <Container sx={{ flexGrow: 1 , padding: 0}} />
              <Container sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                  <MenuIcon sx={{ fontSize: 38, color: "var(--app-color)" }} />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleMenuClose}>How it works</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Features</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Pricing</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Explore</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Contact Us</MenuItem>
                  <MenuItem onClick={handleLogin}>Login</MenuItem>
                  <MenuItem onClick={handleSignUp}>SignUp</MenuItem>
                </Menu>
              </Container>
            </div>
          }
          <Container  sx={{ marginRight: '-30px',flexGrow: 1, display: 'flex', flexDirection: 'row', fontWeight: 'bold', justifyContent: 'flex-end' }}>
            <Button sx={{ marginRight: '10px', fontSize: 12, fontWeight: 'bold', display: matches ? 'none' : 'block' }} color="inherit">How it works</Button>
            <Button sx={{ marginRight: '10px', fontSize: 12, fontWeight: 'bold', display: matches ? 'none' : 'block' }} color="inherit">Features</Button>
            <Button sx={{ marginRight: '10px', fontSize: 12, fontWeight: 'bold', display: matches ? 'none' : 'block' }} color="inherit">Pricing</Button>
            <Button sx={{ marginRight: '10px', fontSize: 12, fontWeight: 'bold', display: matches ? 'none' : 'block' }} color="inherit">Explore</Button>
            <Button sx={{ marginRight: '10px', fontSize: 12, fontWeight: 'bold', display: matches ? 'none' : 'block' }} color="inherit">Contact Us</Button>
            <Button sx={{ marginRight: '0px', fontSize: 15, fontWeight: 'bold', display: matches ? 'none' : 'block', width: '90px' }} onClick={handleLogin} color="inherit">Login</Button>
            <Button sx={{ marginRight: '0px', fontSize: 15, fontWeight: 'bold', display: matches ? 'none' : 'block', width: '90px' }} onClick={handleSignUp} color="inherit">SignUp</Button>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar /> 
      { matches ? (
      <Grid container spacing={2} direction="column">
        <Grid item xs={9}>
          <Container sx={{ paddingTop: '64px', textAlign: 'Left', font: 'bold' }}>
            <Typography variant="h5" sx={{ marginBottom: '1rem', maxWidth:'60%', fontWeight: "bold" }}>
              A Form Builder Solution For Building, Configuring And Maintaining
              Enterprise Level Forms
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: '2rem',  maxWidth:'60%', color: '#2b4252'  }}>
              Automate all your forms using state of the art form builder solution. You can build forms, individual components in the form. 
              This solution is fully maintained on the cloud, ensuring consistent availability.
            </Typography>
            <Button  sx = {{marginRight: '0px', fontSize: 17, height: 50, width: 160, backgroundColor: '#4394E1', color: 'white', fontWeight: 'bold',     ":hover": {
                    backgroundColor: '#84B9ED',
                }}}  color="inherit">Free Trial</Button>
          </Container>
        </Grid>
        <Grid item xs={6}>
            <Avatar src={pic} alt="Logo" sx={{ width: 400, height: 400, right: 0, position: 'absolute', marginTop: '50px', marginRight: '50px' }}  />
        </Grid>
      </Grid>) : 
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Container sx={{ paddingTop: '64px', textAlign: 'Left', font: 'bold' }}>
            <Typography variant="h5" sx={{ marginBottom: '1rem', maxWidth:'60%', fontWeight: "bold" }}>
              A Form Builder Solution For Building, Configuring And Maintaining
              Enterprise Level Forms
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: '2rem',  maxWidth:'60%', color: '#2b4252'  }}>
              Automate all your forms using state of the art form builder solution. You can build forms, individual components in the form. 
              This solution is fully maintained on the cloud, ensuring consistent availability.
            </Typography>
            <Button  sx = {{marginRight: '0px', fontSize: 17, height: 50, width: 160, backgroundColor: '#4394E1', color: 'white', fontWeight: 'bold',     ":hover": {
                    backgroundColor: '#84B9ED',
                }}}  color="inherit">Free Trial</Button>
          </Container>
        </Grid>
        <Grid item xs={6}>
            <Avatar src={pic} alt="Logo" sx={{ width: 550, height: 550, right: 0, position: 'absolute', marginTop: '-300px', marginRight: '50px' }}  />
        </Grid>
      </Grid> }
      <div className={ matches ? classes.PrivacyButtonDivSmall:  classes.PrivacyButtonDiv}>
        <button className={classes.PrivacyButton} onClick={handlePrivacyButton} > Privacy Policy Terms of Use Software Agreement Open Source License</button>
      </div>
    </div>
  );
};

export default LandingPageMUI;
