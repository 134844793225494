import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, Toolbar, IconButton, Button } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectUserModal from './SelectUserModal/SelectUserModal';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
} from '@mui/material';

const EditUsers = (props) => {
    const [usersData, setUsersData] = useState(
      []
      // {
      //   builderUsers: [
      //     {
      //       username: '1111',
      //       email: 'abc@gmail.com'
      //     }
      //   ],
      //   readerUsers: [
      //     {
      //       username: '2222',
      //       email: 'xyz@gmail.com'
      //     }
      //   ]
      // }
    );
    const [cogntioUser, setCogntioUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdminAccess, setIsAdminAccess] = useState(false);
    const [isAddBuilder, setIsAddBuilder] = useState(false);
    const [isRemoveBuilder, setIsRemoveBuilder] = useState(false);
    const [isAddReader, setIsAddReader] = useState(false);
    const [isRemoveReader, setIsRemoveReader] = useState(false);
    const [selectedUserEmail, setSelectedUserEmail] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userEmailsForModal, setUserEmailsForModal] = useState(false);
    const [currUserEmails, setCurrentUserEmails] = useState(false);

    
    useEffect(() => {

        console.log("inside use effect!");

        const getUsers = async (user) => {
          try {
            const response = await apiGatewayService.getAdminUsers();
            console.log("response from admin users: ", response);
            // const data =   response.data;
            // data.builderUsers = data.readerUsers;
            const data = convertToGroupArray(response.data);
            setUsersData(data);
          } catch (error) {
            console.error('Error fetching forms:', error);
          }
          setIsLoading(false);
        };
        const user = localStorageService.getObject('user');
        console.log("currentCognito User: ", user);
        setCogntioUser(user);
        setIsAdminAccess(utilityFunctions.checkAdminAccess(user));
        getUsers(user);
      }, [props]);


      function convertToGroupArray(groupObject) {
        const result = [];
      
        for (const [group, users] of Object.entries(groupObject)) {
          users.forEach(user => {
            result.push({ group: group.slice(0, -1), user: user });
          });
        }
      
        return result;
      };

    const getUsers = async () => {
        try {
            const response = await apiGatewayService.getAdminUsers();
            console.log("response from admin users: ", response);
            // const data =   response.data;
            // data.builderUsers = data.readerUsers;
            const data = convertToGroupArray(response.data);
            setUsersData(data);
            setUsersData(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
        setIsLoading(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const setAllFalse = () => {
        setIsAddBuilder(false);
        setIsRemoveBuilder(false);
        setIsAddBuilder(false);
        setIsRemoveBuilder(false); 
    };

    const handleAddBuilder = () => {
        setAllFalse();
        setIsAddBuilder(true);
        openModal();
        setCurrentUserEmails(usersData.readerUsers);
    };
    
    const handleRemoveBuilder = () => {
        setAllFalse();
        setIsRemoveBuilder(true);
        openModal();
        console.log(usersData.builderUsers);
        setCurrentUserEmails(usersData.builderUsers);
    };

    const handleAddReader = () => {
        setAllFalse();
        setIsAddReader(true);
        openModal();
        setCurrentUserEmails(usersData.builderUsers);
    };
    
    const handleRemoveReader = () => {
        setAllFalse();
        setIsRemoveReader(true);
        openModal();
        setCurrentUserEmails(usersData.readerUsers);
    };

    const handleSubmit = async () => {
        console.log("This is the curren user: ", selectedUserEmail);
        console.log(" Is Add New Builder: ", isAddBuilder);

        try {
            
            let response;
            if (isAddBuilder) {
                response = await apiGatewayService.updateGroup(selectedUserEmail, 'builder');
                console.log(response);
            } 
            
            else if (isAddReader) {
                response = await apiGatewayService.updateGroup(selectedUserEmail, 'reader');
                console.log(response);
            }

            closeModal();
            await getUsers();

        } catch(error){
            console.log("Could not update user group: ", error);
        }

    };

    const handleClose = () => {
        closeModal();
        setAllFalse();
    };


  const setReaderGroup = () => {

  };

  const setBuilderGroup = () => {

  };


  const handleChange = async (index, e) => {
    console.log(index);
    console.log(e);
    if(usersData[index].group!==e.target.value){
      usersData[index].group = e.target.value;
      console.log(usersData);
      setUsersData(Array.from(usersData));
      try {
            
        let response;
        if (e.target.value === 'builderUser') {
            response = await apiGatewayService.updateGroup(usersData[index].user.email, 'builder');
            console.log(response);
        } 
        
        else if (e.target.value === 'readerUser') {
            response = await apiGatewayService.updateGroup(usersData[index].user.email, 'reader');
            console.log(response);
        }

        closeModal();
        await getUsers();

    } catch(error){
        console.log("Could not update user group: ", error);
    }

    }



  };
    
  return (
    <div>
    <Container>
    <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderTopLeftRadius: "10px", borderTopRightRadius: "10px" , borderRadius: '10px', padding: '0px', marginLeft: '15px', marginRight: '25px', color: 'white'}}>
        <IconButton  sx = {{marginLeft: '-20px'}} edge="start" color="inherit" aria-label="menu">
            < GroupAddIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            Edit  Users
          </Typography>


            <Button sx={{
                marginRight: '5px',
                ":hover": {
                    backgroundColor: '#F0F4F4',
                    color: '#2b4252',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0', // Custom background color for disabled state
                    color: 'grey', // Custom text color for disabled state
                },

            }} color="inherit" startIcon={<AddIcon />} onClick={handleAddBuilder} disabled={isAdminAccess}>
                Add Builder
            </Button>
{/* 
            < Button sx={{
                marginRight: '5px',
                ":hover": {
                    backgroundColor: '#F0F4F4',
                    color: 'red',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0', // Custom background color for disabled state
                    color: 'grey', // Custom text color for disabled state
                },
            }}
                color="inherit" startIcon={<DeleteIcon />} onClick={handleRemoveBuilder} disabled={isAdminAccess}>
                Remove Builder
            </Button> */}

            <Button sx={{
                marginRight: '5px',
                ":hover": {
                    backgroundColor: '#F0F4F4',
                    color: '#2b4252',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0', // Custom background color for disabled state
                    color: 'grey', // Custom text color for disabled state
                },

            }} color="inherit" startIcon={<AddIcon />} onClick={handleAddReader} disabled={isAdminAccess}>
                Add Reader
            </Button>

            {/* < Button sx={{
                marginRight: '-10px',
                ":hover": {
                    backgroundColor: '#F0F4F4',
                    color: 'red',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0', // Custom background color for disabled state
                    color: 'grey', // Custom text color for disabled state
                },
            }}
                color="inherit" startIcon={<DeleteIcon />} onClick={handleRemoveReader} disabled={isAdminAccess}>
                Remove Reader
            </Button> */}

        </Toolbar>
        {/* isModalOpen, handleClose, users, selectedUser, setSelectedUser, handleSubmit } */}
      {isModalOpen && <SelectUserModal isModalOpen={ isModalOpen} handleClose={handleClose} users = {currUserEmails} selectedUse={selectedUserEmail} setSelectedUser={setSelectedUserEmail} handleSubmit={handleSubmit} ></SelectUserModal>}  
      <Container sx={{ width: '100%' }}>
  <Box sx={{ my: 4, width: '100%' }}>
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      {/* <FormLabel component="legend">User Groups</FormLabel> */}
      {usersData.map((user, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ mr: 2, fontWeight: 'bold' , }}>{user.user.email}</Typography>
          <RadioGroup
            row
            aria-label="group"
            name={user.user.username}
            value={user.group}
            onChange={ (e)=> handleChange(index,e)}
            color='var(--app-color)'
          >
            <FormControlLabel
              value="builderUser"
              control={<Radio sx={{
                color: 'var(--app-color)',
                '&.Mui-checked': {
                  color: 'var(--app-color)',
                },
              }} />}
              label="Builder"
            />
            <FormControlLabel
              value="readerUser"
              control={<Radio sx={{
                color: 'var(--app-color)',
                '&.Mui-checked': {
                  color: 'var(--app-color)',
                },
              }} />}
              label="Reader"
            />
          </RadioGroup>
        </Box>
      ))}
    </FormControl>
  </Box>
</Container>
    </Container>
    </div>
  );
};

export default EditUsers;
