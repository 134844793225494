// SelectionModalComponent.jsx
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import classes from './SelectionModal.module.css';

Modal.setAppElement('#root'); // Set the root element for accessibility

const SelectionModalComponent = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [displayChoice, setDisplayChoice] = useState(false);
  const [selectFromExisting, setSelectFromExisting] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setDisplayChoice(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDisplayChoice(false);
    setSelectFromExisting(false);
    setCreateNew(false);

  };

  const handleSelection = (type) => {
    console.log("Check selected entity here: ", type);
    if(type==='existing'){
      setSelectFromExisting(true);
      setCreateNew(false);
    }
    else{
      console.log("HEREEEEEEEEEEEEEE:");
      setSelectFromExisting(false);
      setCreateNew(true);
      props.addOptionsIntoField(undefined);
      closeModal();
      setDisplayChoice(false);
      return; //we return here as we do not want the display choice to be false otherwise display choice willbe alse and we will have selectio enabled
    }
    setDisplayChoice(false);
    console.log("createNew: ",createNew);
    console.log("selectFromExisting: ", selectFromExisting);
    console.log("displayChoice: ", displayChoice);
  };

  const submit = (option) => {
    console.log("INSIDE FIELD SELECTOR!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    props.addOptionsIntoField(option);
    setDisplayChoice(false);
    // setModalIsOpen(false);
    closeModal();
  }
  //Removing marginsg because dont need them for fields
  return (
    <div index = {props.index} style={{ display: 'flex', flexDirection: 'row' }}>
      <button className={ (props.type==='Options') ? classes.ButtonOptions : classes.Button } style={ (props.type==='Fields') ? {  'margin-top': '5px', 'margin-left': '0px' }: {}} onClick={openModal}>{`Add ${props.type}`}</button>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            position: 'relative'
          },
        }}
      >
        <button onClick={closeModal} style={{ float: 'right', margin: '5px' }}>×</button> */}
        {displayChoice  && 
        <div className={classes.DisplayButtonContainer}>
        <button className={ (props.type==='Options') ? classes.ButtonOptions : classes.Button } name={'existing'} onClick={(e)=>handleSelection(e.target.name)}> Select </button>
        <button className={ (props.type==='Options') ? classes.ButtonOptions : classes.Button } name={'new'} onClick={(e)=>handleSelection(e.target.name)}>Create New</button>
        </div>
        }
        {/* {createNew &&  < props.Builder />} */}
        {selectFromExisting && <props.Selector field = {props.field} submitOption = {submit}/>}
      {/* </Modal> */}
    </div>
  );
};

export default SelectionModalComponent;
