import React, { useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import { saveAs } from 'file-saver';

const ImportOptionsModal = ({ open, handleClose, handleUpload, jsonTemplate }) => {


  const handleDownloadTemplate = () => {
    const blob = new Blob([JSON.stringify(jsonTemplate, null, 2)], { type: "application/json" });
    saveAs(blob, "options_template.json");
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const jsonContent = JSON.parse(e.target.result);
        handleUpload(jsonContent);
      } catch (error) {
        alert("Invalid JSON file. Please ensure the file follows the correct template.");
      }
    };

    reader.readAsText(file);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Import Options</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please choose an action:
        </Typography>
        <Button
          startIcon={<GetAppIcon />}
          onClick={handleDownloadTemplate}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white', // Set text color to white
            fontWeight: 'normal',
            textTransform: 'none', // Prevent uppercase text
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
          variant="outlined"
        >
          Download Template
        </Button>
        <Button
          startIcon={<FileUploadIcon />}
          component="label"
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white', // Set text color to white
            fontWeight: 'normal',
            textTransform: 'none', // Prevent uppercase text
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
          variant="outlined"
        >
          Upload JSON
          <input
            type="file"
            accept="application/json"
            hidden
            onChange={handleFileUpload}
          />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}
        sx={{
          mt: 2,
          width: '100%',
          color: 'var(--button-color)', // Set text color to white
          backgroundColor: 'transparent',
          borderColor: 'var(--button-color)',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            borderColor: 'var(--button-color)',
          },
        }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportOptionsModal;