import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FiltersModal = ({ open, handleClose, handleSubmit, inputFilters }) => {
  const filterKeys = ['FILTER1', 'FILTER2', 'FILTER3', 'FILTER4'];
  const [finalFilters, setFinalFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});

  useEffect(() => {
    // currentFilters.map((val, index)=> `FILTER${index}`); //For future
     setCurrentFilters(filterKeys);
     const temp = {};
     filterKeys.forEach((key, index)=>{
        temp[key] = inputFilters[index];
     });
     setFinalFilters(temp);
  }, []);

  const handleChange = (key, value) => {
    // debugger
    setFinalFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    // finalFilters[key] = value;
    console.log('final filters: ', finalFilters);
  };

  const onSubmit = () => {
    handleSubmit(finalFilters);
    handleClose(); // Close the modal after submission
  };

  const convertObjectToArray = (obj) => {
    return Object.entries(obj).map(([key, value]) => ({ [key]: value }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Enter Filters
        </Typography>
        {filterKeys.map((key) => (
            <div key={key}>
                <Typography variant="body4" component="label">
                    {key}
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(key, e.target.value)}
                    value={finalFilters[key]}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: 'white'
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 40,
                        width: '100%',
                      },
                    }}
                />
            </div>
        ))}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onSubmit} sx={{
          mt: 2,
          width: '100%',
          backgroundColor: 'var(--button-color)',
          color: 'white', // Set text color to white
          fontWeight: 'normal',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            backgroundColor: 'var(--button-hover-color)',
          },
        }} >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default FiltersModal;
