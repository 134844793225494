import React, { useState, useEffect } from 'react';
import { 
  Box, Button, IconButton, List, ListItem, ListItemText, Typography, 
  Container, Toolbar, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, InputAdornment 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import FieldBuilder from '../FormBuilder/FieldBuilder/FieldBuilder';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Fields = () => {
  const [fields, setFields] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterFormName, setFilterFormName] = useState('');
  const [isView, setIsView] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
    setAllFalse();
    await getAllFields();
  }

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getFieldsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.fieldName < b.fieldName) return -1;
          if (a.fieldName > b.fieldName) return 1;
          return 0;
        });
        setFields(Array.from(dataWithUpdatedKeyAndContent));
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllFields = async () => {
    try {
      const response = await apiGatewayService.getFieldsData();
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.fieldName < b.fieldName) return -1;
        if (a.fieldName > b.fieldName) return 1;
        return 0;
      });
      setFields(Array.from(dataWithUpdatedKeyAndContent));
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setIsView(false);
    setIsViewIndex(null);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleImport = () => {
    setAllFalse();
    alert("This feature is in progress.");
  };

  const handleEdit = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    await getFieldById(filteredFields[index].fieldId);
    setTimeout(() => {
      setIsEdit(true);
      handleOpen();
    }, 300);
  };

  const handleView = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    await getFieldById(filteredFields[index].fieldId);
    setTimeout(() => {
      setIsView(true);
      handleOpen();
    }, 300);
  };

  const handleDelete = async (index) => {
    try {
      const postData = {
        fieldNames: [filteredFields[index].fieldName],
      };
      await apiGatewayService.deleteFieldsData(postData);
      setAllFalse();
      const fieldsNew = fields.filter((option, index2) => index2 !== index);
      setFields(Array.from(fieldsNew));
    } catch (error) {
      console.error("Error: ", error);
      alert('Field cannot be deleted');
    }
  };

  const getFieldById = async (fieldId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFieldsDataById(fieldId);
      setCurrentField(response.data);
    } catch (error) {
      console.error('Error in getting field:', error);
    }
    setIsLoading(false);
  };

  const filteredFields = fields.filter((field) =>
    field.fieldName.toLowerCase().includes(filterFormName.toLowerCase())
  );

  return (
    <Container sx={{ padding: '0px', margin: 0, minWidth: '95%' }}>
      <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white' }}>
        <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
          <FormatListBulletedIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Fields
        </Typography>
        {
          (isEdit || isAddNew || isView) &&
          <Button sx={{
            width: '100px',
            marginRight: '-10px',
            ":hover": {
              backgroundColor: '#F0F4F4',
              color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '100px' }} />} onClick={handleClose} disabled={isOnlyReadAccess}>
            Back
          </Button>
        }
        {
          (!isEdit && !isAddNew && !isView) &&
          <div>
            <Button sx={{
              marginRight: '10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAccess}>
              Add New
            </Button>
            <Button sx={{
              marginRight: '-10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<ImportExportIcon />} onClick={handleImport} disabled={isOnlyReadAccess}>
              Import
            </Button>
          </div>
        }
      </Toolbar>

      {isLoading && <CircularLoader />}
      {(!isLoading && !isAddNew && !isEdit && !isView) &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Field Name"
                    value={filterFormName}
                    onChange={(e) => setFilterFormName(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFields.map((option, index) => (
                <TableRow key={index}>
                <TableCell sx={{ height: 40, padding: 1, paddingLeft: 2 }}>{option.fieldName}</TableCell>
                <TableCell align="right" sx={{ height: 40, padding: 1 }}>
                    <IconButton aria-label="view"  onClick={() => handleView(index)} disabled={isOnlyReadAccess}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)} >
                      <EditIcon />
                    </IconButton>
                    <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

      {isAddNew && <FieldBuilder field={{}} handleClose={handleClose} />}
      {isView && <FieldBuilder field={currentField} handleClose={handleClose} isView = {true} fieldId = {filteredFields[viewIndex].fieldId || null } />}      
      {isEdit && <FieldBuilder field={currentField} handleClose={handleClose} isEdit = {true} fieldId = {filteredFields[viewIndex].fieldId || null } />}
    </Container>
  );
};

export default Fields;