import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import authService from '../../../common/services/auth.service';
import { useNavigate } from 'react-router-dom'; // Updated for react-router-dom v6
import CircularLoader from '../../Loader/Loader';

const SubscriptionValidation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const validateSubscription = async () => {
      try {
        navigate('/sideMenu');
        // Replace these with the actual username and password

        // Sign in the user
        // const user = await authService.getCurrentAuthenticatedUser();
        
        // Fetch user groups
        // const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];

        // Check if user is in activeSubscription group
        // if (userGroups.includes('activeSubscription')) {
        //   // Update token in local storage
        //   localStorage.setItem('user', user);
          
        //   // Redirect to main side menu
        //   navigate('/sideMenu');
        // } else {
        //   // Handle case where user is not in activeSubscription group
        //   alert('User does not have active subscription');
        //   navigate('/sideMenu');
        //   console.log('User does not have an active subscription.');
        // }
      } catch (error) {
        console.error('Error during subscription validation:', error);
      }
    };

    // setTimeout(validateSubscription(), 2000);
    validateSubscription();
  }, [navigate]);

  return (
    <div>
      Validating subscription...
    </div>
  );
};

export default SubscriptionValidation;
