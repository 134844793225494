import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  InputLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FormGroupSelector = ({ isOpen, handleClose, submitGroup }) => {
  const [availableGroups, setAvailableGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getFormgroups();
        const data = response.data;
        setAvailableGroups(data);
      } catch (error) {
        console.error('Error fetching form groups:', error);
      }
      setIsLoading(false);
    };

    fetchGroups();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredGroups = availableGroups.filter((group) =>
    group.groupName.toLowerCase().includes(filter.toLowerCase())
  );

  const handleChange = (event) => {
    const selected = availableGroups.find((group) => group.groupName === event.target.value);
    setSelectedGroup(selected);
  };

  const handleSubmit = async () => {
    await submitGroupForForm(selectedGroup.groupName);
    handleClose();
  };

  const submitGroupForForm = async (groupName) => {
    try {
      setIsLoading(true);
      const newFormObject = {
        groupName: groupName,
        // Add other form properties here as needed
      };
      submitGroup(groupName);
    //   await apiGatewayService.submitGroupForForm(newFormObject);
      
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error submitting group for form:', error);
    }
  };

  
  const handleModalSubmitRemoveGroup = ()=> {
    submitGroup(null); // Pass the groupName to the parent component
    handleClose(); // Close the modal
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-group-selector-title"
      aria-describedby="form-group-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <h1>Select Form Group</h1>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </Box>
        ) : (
          <>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Filter Groups"
              value={filter}
              onChange={handleFilterChange}
              sx={{
                marginBottom: '16px',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />

            <InputLabel sx={{ marginBottom: '8px' }}>Select a group</InputLabel>
            <Select
              fullWidth
              value={selectedGroup.groupName || ''}
              onChange={handleChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
            >
              <MenuItem value="" disabled>
                <em>Select a group</em>
              </MenuItem>
              {filteredGroups.map((group, index) => (
                <MenuItem key={index} value={group.groupName}>
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
              <Button 
              onClick={handleModalSubmitRemoveGroup} 
              sx={{
                mt: 2,
                mb: 1, // Margin bottom for space between buttons
                width: '100%',
                backgroundColor: 'var(--button-color-red)',
                color: 'white', 
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color-red)',
                },
              }}
            >
              Remove Group
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default FormGroupSelector;