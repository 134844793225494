import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const MultiCheckBoxInputs = ({ open, onClose, onSubmit, multiCheckBoxInputsInput }) => {
  const [multiCheckBoxInputs, setMultiCheckBoxInputs] = useState([]);

  useEffect(() => {
    if (multiCheckBoxInputsInput.length > 0) {
      setMultiCheckBoxInputs(Array.from(multiCheckBoxInputsInput));
    }
  }, [open, multiCheckBoxInputsInput]);

  const handleAdd = () => {
    setMultiCheckBoxInputs([...multiCheckBoxInputs, { name: '' }]);
  };

  const handleUpdateName = (index, value) => {
    const updatedInputs = [...multiCheckBoxInputs];
    updatedInputs[index].name = value;
    setMultiCheckBoxInputs(updatedInputs);
  };

  const handleRemove = (index) => {
    const updatedInputs = multiCheckBoxInputs.filter((_, i) => i !== index);
    setMultiCheckBoxInputs(updatedInputs);
  };

  const moveUp = (index) => {
    if (index === 0) return;
    const updatedInputs = [...multiCheckBoxInputs];
    [updatedInputs[index - 1], updatedInputs[index]] = [updatedInputs[index], updatedInputs[index - 1]];
    setMultiCheckBoxInputs(updatedInputs);
  };

  const moveDown = (index) => {
    if (index === multiCheckBoxInputs.length - 1) return;
    const updatedInputs = [...multiCheckBoxInputs];
    [updatedInputs[index + 1], updatedInputs[index]] = [updatedInputs[index], updatedInputs[index + 1]];
    setMultiCheckBoxInputs(updatedInputs);
  };

  const handleSubmit = () => {
    onSubmit(multiCheckBoxInputs);
    onClose();
  };

  const handleRemoveAll = () => {
    setMultiCheckBoxInputs([]);
    onSubmit([]); // Submit an empty array to represent removal of all inputs
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          Multi CheckBox Inputs
        </Typography>
        <List>
          {multiCheckBoxInputs.map((input, index) => (
            <ListItem key={index} sx={{ p: 0, mt: 1 }}>
              <TextField
                value={input.name}
                onChange={(e) => handleUpdateName(index, e.target.value)}
                fullWidth
                placeholder={`Input ${index + 1} Name`}
                sx={{
                  width: '100%',
                  mr: 1,
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => moveUp(index)} disabled={index === 0}>
                  <ArrowUpwardIcon sx={{ color: 'var(--button-color)', ml: 3, ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
                </IconButton>
                <IconButton edge="end" onClick={() => moveDown(index)} disabled={index === multiCheckBoxInputs.length - 1}>
                  <ArrowDownwardIcon sx={{ color: 'var(--button-color)', ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
                </IconButton>
                <IconButton edge="end" onClick={() => handleRemove(index)}>
                  <DeleteIcon sx={{ color: 'var(--button-color-red)', ":hover": { color: 'var(--button-hover-color-red)' } }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          onClick={handleAdd}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Add Input
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={handleRemoveAll}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color-red)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color-red)',
            },
          }}
        >
          Remove All
        </Button>
      </Box>
    </Modal>
  );
};

export default MultiCheckBoxInputs;