import React, { useEffect, useState } from 'react';
import classes from './SignUpType.module.css';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../common/services/localStorage.service';
import logo from '../LandingPage/citra-header-logo.png';
import logoBlack from './citra-header-logo.png';
import { AppBar, Toolbar, Typography, Avatar } from '@mui/material';
import logoNew from '../SideMenu/FreeForm-Logo_White.png';

const SignUpType = ({auth}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState(null);
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);

  useEffect(()=> {
    // const signUpType = localStorageService.getObject('signUpType');
    // if (signUpType === 'individual') {
    //   setIsIndividual(true);
    //   navigate('/signIn ');
    // };
  }, []);

  const handleIndividual = () => {
    localStorageService.setObject('signUpType', 'individual');
    navigate('/signUp');
  };

  const handleOrganization = () => {
    localStorageService.setObject('signUpType', 'organization');
    navigate('/signUp');
  };
  
  const handlePrivacyButton = () => {

  };

  return (
    <div>
        <AppBar position="fixed">
        <Toolbar   sx={{ backgroundColor: '#2b4252'}}>
          {/* <Avatar src={logo} alt="Logo" sx={{ marginRight: '10px',width: '40px', height: '40px' }} />
          <Typography variant="h5" noWrap>
            Freeform IO
          </Typography> */}
          <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-35px', marginRight: '0px', marginTop: '5px', width: '200px', height: '60px' }} />  
        </Toolbar>
      </AppBar>
      <div className={classes.Container}>
        <div className={classes.Border}>
        <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logoBlack} alt="ao-logo"/>
         </div>
         <hr></hr>
        {/* <h1>Free Form Registration</h1> */}
        <h5 className={classes.Heading}>How would you like to sign up?</h5>
        <button className={classes.Button} onClick={handleIndividual}>Individual</button>
        <button className={classes.Button} onClick={handleOrganization}>Organization</button>
        {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
       </div>
      </div>
   </div>
  );
};

export default SignUpType;
