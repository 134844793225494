class LocalStorageService {
    // Store an object in local storage
    setObject(key, obj) {
        try {
            const serializedObj = JSON.stringify(obj);
            localStorage.setItem(key, serializedObj);
        } catch (error) {
            console.error("Error saving to local storage", error);
        }
    }

    // Retrieve an object from local storage
    getObject(key) {
        try {
            const serializedObj = localStorage.getItem(key);
            if (serializedObj === null) {
                return null;
            }
            return JSON.parse(serializedObj);
        } catch (error) {
            console.error("Error reading from local storage", error);
            return null;
        }
    }

    // Delete an object from local storage
    removeObject(key) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error("Error removing from local storage", error);
        }
    }
}

const localStorageService = new LocalStorageService();
export default localStorageService;