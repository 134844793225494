import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import './Loader.css'; // Ensure the path is correct

const CircularLoader = () => {
  return (
    // <Box className="Centered">
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15%'}}>   
      <CircularProgress size={100} sx={{ color: 'var(--app-color)' }} />
    </div>
    // </Box>
  );
}; 

export default CircularLoader;
