import React from 'react';
import { Modal, Box, Typography, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const SelectUserModal = ({ isModalOpen, handleClose, users, selectedUser, setSelectedUser, handleSubmit }) => {
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select User
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-user-label">User</InputLabel>
          <Select
            labelId="select-user-label"
            id="select-user"
            value={selectedUser}
            label="User"
            onChange={handleUserChange}
          >
            {users.map((user) => (
              <MenuItem key={user.username} value={user.email}>
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={handleClose} sx={{ mr: 2, color: 'var(--app-color)', '&:hover': { }}}>Cancel</Button>
          <Button onClick={handleSubmit} sx={{ backgroundColor: 'var(--app-color)', '&:hover': { backgroundColor: 'var(--button-hover-color)' } }} variant="contained" >Submit</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectUserModal;
