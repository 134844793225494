import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  InputLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FieldsSelector = ({ isOpen, handleClose, submitField }) => {
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [fieldSelected, setFieldsSelected] = useState(false);
  const [selectedFieldDisplayName, setSelectedFieldDisplayName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getFieldsData();
        const data = response.data;
        setAvailableFields(data);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };
    getOptions();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredFields = availableFields.filter((option) =>
    option.fieldName.toLowerCase().includes(filter.toLowerCase())
  );

  const handleChange = (event) => {
    const selectOption = availableFields.find((option) => option.fieldName === event.target.value);
    setSelectedField(selectOption);
    setSelectedFieldDisplayName(selectOption.fieldName);
    setFieldsSelected(true);
  };

  const handleSubmit = async () => {
    const field = await getFieldById(selectedField.fieldName);
    submitField(field);
    handleClose();
  };

  const getFieldById = async (fieldId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFieldsDataById(fieldId);
      setIsLoading(false);
      return response.data
    } catch (error) {
      setIsLoading(false);
      console.error('Error in getting field:', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="fields-selector-title"
      aria-describedby="fields-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <h1>Select Field</h1>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </Box>
        ) : (
          <>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Filter Fields"
              value={filter}
              onChange={handleFilterChange}
              sx={{
                marginBottom: '16px',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />

            <InputLabel sx={{ marginBottom: '8px' }}>Select an option</InputLabel>
            <Select
              fullWidth
              value={selectedFieldDisplayName}
              onChange={handleChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
            >
              <MenuItem value="" disabled>
                <em>Select an option</em>
              </MenuItem>
              {filteredFields.map((option, index) => (
                <MenuItem key={index} value={option.fieldName}>
                  {option.fieldName}
                </MenuItem>
              ))}
            </Select>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default FieldsSelector;