import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Paper, Container  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormSectionsBuilder from '../FormBuilder/FormSectionsBuilder/FormSectionsBuilder';
import ReactJson from 'react-json-view';

const FormsModal = ({ open, handleClose, form, isViewJSON }) => {

  useEffect(()=>{
    console.log('form received: ', form);
    console.log('isViewJSON: ', isViewJSON);
    if(isViewJSON){
      setIsViewEnabledJSON(true);
    }
  }, [form, isViewJSON]);

  const [isViewEnabledJSON, setIsViewEnabledJSON] = useState(false);

  // Customize your modal content here
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
     sx = {{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
    }}
    >
      {isViewEnabledJSON ? (
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Box sx={{ p: 0, maxWidth: '60%', maxHeight: '80%', width: '100%', marginTop: '-50px', overflow: 'auto', border: '10px solid' , borderRadius: '5px', borderColor: '#2b4252'}}>
            <Typography variant="h6" gutterBottom>
              {form?.formHeaers?.FormName}
            </Typography>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                maxHeight: '100%',
                maxWidth: '100%',
                overflow: 'auto',
                border: '0px solid #ccc',
                padding: 0,
                margin: 0,
                marginTop: '-5px',
                paddingBottom: '5px'
              }}
            >
              <ReactJson src={form} theme="githubLightTheme" />
            </Paper>
          </Box>
        </Container>
      ) : <Box sx={{
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', 
        overflowX: 'auto',
        justifyContent: 'center'
      }}>
        {/* <Typography variant="h6" id="modal-title">
          Modal Title
        </Typography> */}
        <Typography variant="body1" id="modal-description" sx={{ mt: 2 }}>
          {(Object.keys(form).length===undefined) ? <FormSectionsBuilder form = {{}} handleClose = {handleClose}/> : <FormSectionsBuilder form = {form} handleClose = {handleClose}/>}
        </Typography>
        {/* <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'text.secondary',
            backgroundColor: '#2b4252',
            color: 'white',
            ":hover": {
                backgroundColor: '#2b4252',
                color: 'white',
            }
          }}
        >
          <CloseIcon />
        </IconButton>

      </Box> }

    </Modal>
  );
}

export default FormsModal;
