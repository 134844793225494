import React, { useState, useEffect } from 'react';
import classes from './ViewForms.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';

const ViewForms = (props) => {
  const [availableForms, setAvailableForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState({});
  const [selectedFormDisplayName, setSelectedFormDisplayName] = useState('');
  const [formSelected, setFormsSelected] = useState(false);
  const [showForm, setShowForm] = useState(false);
  // const [enableSubmitOption, setEnableSubmitOption] = useState(false);

  // useEffect(()=>{
  //   if(props.addOptionsIntoField){
  //     setEnableSubmitOption(true);
  //   };
  // }, [props])

  useEffect(() => {
    const getForms = async () => {
      // try {
      //   const response = await apiGatewayService.getFormsData();
      //   const data = response.data.map((d)=>JSON.parse(d.Content));
      //   console.log("response: ", data); 
      //   setAvailableForms(data);
      // } catch (error) {
      //   console.error('Error fetching forms:', error);
      // }
    };
    getForms();
  }, []);

  const handleChange = (event) => {
    const selectOption = availableForms.filter((option)=>option.formHeaders.FormName===event.target.value)[0];
    setSelectedFormDisplayName(selectOption.formHeaders.FormName);
    setSelectedForm({...selectOption});
    setFormsSelected(true);
    console.log(selectOption);
  };


  // This will call the submitOption of the modal and then in the submit option of the modal we can
  // pass submit option of the parent component where we have opened the modal. Through this way we
  // can easily pass the selected value to the parent component.
  const openForm = () => {
    // const selectOption = availableForms.filter((option)=>option.displayName===selectedForm)[0];
    console.log("selectedForm: ", selectedForm);
    // console.log("selectedForm: ", selectOption);
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <div className={classes.FormFieldContainer}>
      <h1>View Forms</h1>
      <select className={classes.ValueContainer} value={ selectedFormDisplayName } onChange={handleChange}>
        <option value="" disabled>Select a Form</option>
        {availableForms.map((option, index) => (
          <option key={index} value={option.formHeaders.FormName}>
            {option.formHeaders.FormName}
          </option>
        ))}
      </select>
     <button onClick={openForm} className={classes.Button}> Open Form </button>
     <button onClick={closeForm} className={classes.Button}> Close Form </button>
     { showForm &&
            <div style={{ padding: '20px', fontFamily: 'monospace' }}>
            <h2>Form Data</h2>
            <pre>{JSON.stringify(selectedForm, null, 2)}</pre>
          </div>
     }


      {/* <p>Selected Option: {selectedForm}</p> */}
    </div>
  );
};

export default ViewForms;
