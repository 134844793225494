import { React, useEffect, useState } from "react";
import SectionFieldsBuilder from "../SectionFieldsBuilder/SectionFieldsBuilder";
import classes from  './SectionBuilder.module.css';
import apiGatewayService from "../../../common/services/apiGatewayService";
import validationService from "../../../common/validationService.service";
import localStorageService from "../../../common/services/localStorage.service";
import utilityFunctions from "../../../common/services/utilityFunctions";
import { TextField } from "@mui/material";

const SectionBuilder = (props) => {

    const initialSectionState = 
    {
        title: "string",
        fields: "array"
    };

    useEffect(() => {
      console.log('Props have changed SectionBuilder: ', props);
      setSection(props.section);
      setSectionTitle(props.section.title);
      const currFieldsArray = props?.section?.fields ? props?.section?.fields : []; 
      setSectionFieldsArray(currFieldsArray); 
      if(props.isForm){
        setIsForm(true);
      };
      if(props.isEdit){
        setIsEdit(true);
        setSectionId(props.sectionId);
      }
      if(props?.section?.dependant){
        setDependant(props.section.dependant);
      }
      const user = localStorageService.getObject('user');
      setCogntioUser(user);
      setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
      console.log("currentCognito User: ", user);
    }, [props]);
    
    const [sectionTemplate, setSectionTemplate] = useState(initialSectionState);

    const [section, setSection] = useState({});

    const [sectionTitle, setSectionTitle] = useState("");
    
    const [sectionFieldsArray, setSectionFieldsArray] = useState([]);

    const [isForm, setIsForm] = useState(false);

    const [cogntioUser, setCogntioUser] = useState(null);

    const [isOnlyReadAcess, setIsOnlyReadAccess] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [sectionId, setSectionId] = useState(null);

    const [dependant, setDependant] = useState(null);

    const convertToCapital = (value) => {
      return `${value[0].toUpperCase()}${value.slice(1)}`;
    };

    const updateSectionFieldsArray = (fieldsArrayNew) => {
      setSectionFieldsArray([...fieldsArrayNew]);
      handleSubmitSection();
    }

    const removeFieldFromFieldsArray = (fieldsArrayNew, index) => {
      console.log("removeFieldFromFieldsArray: ", fieldsArrayNew);
      debugger
      const newFieldsArrayCopy = [...fieldsArrayNew].filter((_, ind)=> ind!==index);
        setSectionFieldsArray(newFieldsArrayCopy);
        const finalSection = {
          title: sectionTitle,
          fields: newFieldsArrayCopy,
       }
       updateDependantInSection(finalSection);
      debugger
      if(isForm){
        props.updateSectionsArray(finalSection, props.indexSectionsArray);
      };
    }

    const handleTitleInputChange = (e) => {
        const titlteValue = e.target.value;
        setSectionTitle(titlteValue);
        console.log("Section tite is: ", sectionTitle);
        handleSubmitSection();
    };

    const handleRemoveSection = () => {
      setSection({});
      setSectionFieldsArray([]);
      if(isForm){
        props.updateSectionsArray(undefined, props.indexSectionsArray);
      };
    };

    const handleSubmitSection = () => {
      const finalSection = {
          title: sectionTitle,
          fields: sectionFieldsArray,
      };
      updateDependantInSection(finalSection);
      setSection(finalSection);
      console.log("Section Submitted: ", finalSection);
      
      if(isForm){
        props.updateSectionsArray(finalSection, props.indexSectionsArray);
      };
    };

    const handleSubmitSectionToBackend = async (fieldsArray) => {
      const finalSection = {
          title: sectionTitle,
          fields: fieldsArray,
      }
      setSection(finalSection);
      updateDependantInSection(finalSection);
      console.log("Section Submitted: ", finalSection);
      if(isForm){
        props.updateSectionsArray(finalSection, props.indexSectionsArray);
      };
      const sectionObject = {
          displayName: sectionTitle,
          section: finalSection
      };

      if(validationService.hasEmptyKeys(sectionObject)){
        alert('Section keys can not be empty!');
      } else{
        try {
          let response;
          if (isEdit){
            response = await apiGatewayService.putSectionsData(sectionId, sectionObject);
          } else {
            response = await apiGatewayService.postSectionsData(sectionObject);
          }

          console.log(response);
          if(!isForm){
            props.handleClose();
          }
        }
        catch(error){
          console.log("Error in submitting form sections: ", error);
        }
      }
   };

   const updateDependant = (dependant) => {
      console.log("Dependan from the the input: ", dependant);
      if (dependant!==null){
        setDependant(dependant)
      } else {
        setDependant(null)
      }
   };

   const updateDependantInSection = (section) => {
    if (dependant!==null) {
      section['dependant'] = dependant;
    } else {
      delete section['dependant'];
    }
    console.log("current section: ", section);
   };


    const keys = Object.keys(sectionTemplate);

    return (
        <div className={classes.SectionFormContainer}>
          {/* <div className={classes.HeaderContainer}> <h2 style={{margin: '0px', padding: '0px'}}>{  (props.indexSectionsArray) ? `Section ${props.indexSectionsArray+1}`: 'Section'}</h2>
          </div> */}
          {keys.map((key) => (
            <div className={classes.SectionContainer} key={key}>
              <label className={classes.Label}>{convertToCapital(key)}</label>
              {key === 'title' ? (
                // <input
                //   type="text"
                //   name="Section Title"
                //   onChange={handleTitleInputChange}
                //   value={sectionTitle}
                // />

                <TextField
                  type="text"
                  name="Section Title"
                  value={sectionTitle}
                  onChange={handleTitleInputChange}
                  // onBlur={handleMouseOut} // onBlur is equivalent to onMouseOut
                  variant="outlined"
                  size="medium"
                  // disabled = {optionsEnabled}
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              ) : (
                <div>
                  <SectionFieldsBuilder
                    fieldsArray={sectionFieldsArray}
                    updateSectionFieldsArray = {updateSectionFieldsArray}
                    removeFieldFromFieldsArray = {removeFieldFromFieldsArray}
                    handleSubmitSectionToBackend = {handleSubmitSectionToBackend}
                    isForm = {true}
                    isOnlyReadAcess = {isOnlyReadAcess}
                    handleRemoveSection = {handleRemoveSection}
                    isView = {props.isView || false}
                    dependant = {dependant || null}
                    updateDependant = {updateDependant}
                  />
                </div>
              )}
            </div>
          ))}
          <div className={classes.SectionButtonContainer}>
          {/* <button className={classes.Button} onClick={handleSubmitSection}>Submit Section</button> */}
          {/* { !isOnlyReadAcess && <button className={classes.Button} onClick={handleRemoveSection} >Remove Section</button>} */}
          {/* <button className={classes.Button} onClick={handleSubmitSectionToBackend}>Submit Section</button> */}
          </div>
        </div>
      );
      
}

export default SectionBuilder;