import React, { useEffect, useState } from 'react';
import classes from './AuthComponent.module.css';
import apiGatewayService from '../../common/services/apiGatewayService';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../common/services/localStorage.service';
import { Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar } from '@mui/material';
// import logo from '../LandingPage/citra-header-logo.png';
// import logoBlack from './citra-header-logo.png';
import logo from '../../common/images/FreeForm-Logo-Without-Text.png';
// import logoNew from '../SideMenu/FreeForm-Logo.png';
import logoNew from '../../common/images/FreeForm-Logo_White.png';
import Error from '../Error/Error';
import { CircularProgress } from '@mui/material';

const AuthComponent = ({auth}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState(null);
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(()=> {
    const signUpType = localStorageService.getObject('signUpType');
    console.log("Check the signup type: ", signUpType);
    if (signUpType === 'individual') {
      setIsIndividual(true);
    };
  }, []);

  const handleSignUp = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.signUp(email, password);
      // alert('Sign up successful!');
      localStorageService.setObject('user', auth.user);
      setSignUpSuccessfull(true);
      // navigate('/sideMenu');
    } catch (error) {
      // alert('Sign up error: ' + error.message);
      setErrorMessage(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleSignIn = async () => {
    setErrorMessage('');
    setShowPasswordButton(false);
    setIsLoading(true);
    try {
      await auth.signIn(email, password);
      console.log("CHECK FOR THE USER HERE: ");
      console.log(auth.user);
      localStorageService.setObject('user', auth.user);
      updateSignUpType(auth.user);
      // alert('Sign in successful!');
      // navigate('/landingPage');
      navigate('/sideMenu');
    } catch (error) {
      // alert('Sign in error: ' + error.message);
      setErrorMessage(`Error: ${error.message}`);
      if(error.message === 'Incorrect username or password.'){
        setShowPasswordButton(true);
      }
    }
    setIsLoading(false);
  };

  const handleGetUserGroups = async () => {
    try {
      const groups = await auth.getUserGroups();
      alert('User groups: ' + groups.join(', '));
    } catch (error) {
      alert('Error getting user groups: ' + error.message);
    }
  };

  const handleVerifyCode = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.confirmSignUp(email, code);
      // alert('User confirmed successfully');
      const signUpType = localStorageService.getObject('signUpType');
      const response  = await apiGatewayService.assignGroupToUser(email, signUpType);
      console.log(response);
      setSignUpSuccessfull(false);
      // await auth.createUserGroup(email.split['@'], 'Users New grou')
    } catch (error) {
    //  alert('User Not confirmed! '+error.message);
        setErrorMessage(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handlePrivacyButton = () => {

  };

  const updateSignUpType = (user) => {
    const userGroupName = user?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    if(userGroupName.includes('admin') || userGroupName.includes('builder') || userGroupName.includes('reader')) {
      localStorageService.setObject('signUpType', 'organization');
    } else {
      localStorageService.setObject('signUpType', 'individual');
    }
  };

  const handlePasswordReset = async () => {
    setErrorMessage(null);
    setIsLoading(true);
    setPassword(null);
    setCode("");
    await auth.forgotPassword(email);
    setPasswordReset(true);
    setIsLoading(false);
  };

  const handlePasswordResetRemove = () => {
    setPassword(null);
    setCode("");
    setPasswordReset(false);
    setShowPasswordButton(false);
    setErrorMessage(null);
  };
  
  

  const submitPasswordReset = async () => {
    setIsLoading(true);
    await auth.forgotPasswordSubmit(email, code, password);
    setIsLoading(false);
    await handleSignIn();
  };

  return (
<div>
  <AppBar position="fixed">
    <Toolbar sx={{ backgroundColor: '#2b4252' }}>
      {/* <Avatar src={logo} alt="Logo" sx={{ marginRight: '10px', width: '40px', height: '40px' }} />
      <Typography variant="h5" noWrap>
        Freeform
      </Typography> */}
      <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-30px', marginTop: '5px', marginRight: '0px', width: '180px', height: '60px' }} />
    </Toolbar>
  </AppBar>
  {passwordReset ? (
    <div className={classes.Container}>
      <div className={classes.Border}>
        <div className={classes.LogoDiv}>
          <img className={classes.Logo} src={logo} alt="ao-logo" />
        </div>
        <hr />
        {/* <h1>Free Form Registration</h1> */}
        <h5 className={classes.Heading}>Reset your password</h5>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          className={classes.InputClass}
        />
        <input
          type="code"
          value = {code}
          onChange={e => setCode(e.target.value)}
          placeholder="Enter Code"
          className={classes.InputClass}
          maxLength={8}
        />
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="New Password"
          className={classes.InputClass}
          maxLength={8}
        />
        <button className={classes.Button} onClick={submitPasswordReset}>Submit New Password</button>
        <button className={classes.Button} onClick={handlePasswordResetRemove}>Back to signIn</button>
        <Error error={errorMessage} />
        {/* <button className={classes.Button} onClick={handleSignUp}>Sign Up</button> */}
        {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
        {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'var(--app-color)' }} />
        </div>}
      </div>

    </div>
  ) : (
    !signUpSuccessfull ? (
      <div className={classes.Container}>
        <div className={classes.Border}>
          <div className={classes.LogoDiv}>
            <img className={classes.Logo} src={logo} alt="ao-logo" />
          </div>
          <hr />
          {/* <h1>Free Form Registration</h1> */}
          <h5 className={classes.Heading}>Enter your email address to continue</h5>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            className={classes.InputClass}
          />
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            className={classes.InputClass}
            maxLength={8}
          />
          <button className={classes.Button} onClick={handleSignIn}>Sign In</button>
          {showPasswordButton && 
          <button className={classes.Button} onClick={handlePasswordReset}>Reset Password</button>
          }
          <Error error={errorMessage} />
          {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'var(--app-color)' }} />
        </div>}
          {/* <button className={classes.Button} onClick={handleSignUp}>Sign Up</button> */}
          {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
        </div>
      </div>
    ) : (
      <div className={classes.Container}>
        <div className={classes.Border}>
          <div className={classes.LogoDiv}>
            <img className={classes.Logo} src={logo} alt="ao-logo" />
          </div>
          <hr />
          {/* <h3 className={classes.Heading}>Enter your email address to continue</h3> */}
          <input
            type="code"
            onChange={e => setCode(e.target.value)}
            placeholder="Enter Code"
            className={classes.InputClass}
            maxLength={8}
          />
          <button className={classes.Button} onClick={handleVerifyCode}>Verify Code</button>
          <Error error={errorMessage} />
          {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'var(--app-color)' }} />
        </div>}
        </div>
      </div>
    )
  )}
  {/* <div className={classes.PrivacyButtonDiv}>
    <button className={classes.PrivacyButton} onClick={handlePrivacyButton}>
      Privacy Policy Terms of Use Software Agreement Open Source License
    </button>
  </div> */}
</div>

  );
};

export default AuthComponent;
