import { Auth } from 'aws-amplify';
import { region, userPoolId } from '../../globalVariables';
import { CognitoIdentityProviderClient, CreateGroupCommand } from "@aws-sdk/client-cognito-identity-provider";

class AuthService {
  constructor() {
    this.client = new CognitoIdentityProviderClient({ region });
    this.user = null;
    this.password = null;
  }

  async createUserGroup(groupName, description) {
    const params = {
      UserPoolId: userPoolId,
      GroupName: groupName,
      Description: description,
    };

    try {
      const command = new CreateGroupCommand(params);
      const response = await this.client.send(command);
      console.log(`Group ${groupName} created successfully`);
      return response;
    } catch (error) {
      console.error("Error creating group:", error);
      throw error;
    }
  }

  async signUp(email, password) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });
      console.log(user);
      this.password = password;
      return user;
    } catch (error) {
      console.log('Error signing up:', error);
      throw error;
    }
  }

  async confirmSignUp(email, confirmationCode) {
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      // const user = await this.signIn(email, this.password);
      // this.user = user;
      // console.log(user);
      console.log('User confirmed successfully!');
    } catch (error) {
      console.log('Error confirming sign up:', error);
      throw error;
    }
  }

  async signIn(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      this.user = user;
      console.log(user);
      return user;
    } catch (error) {
      console.log('Error signing in:', error);
      throw error;
    }
  }

  async signInDefault(email) {
    try {
      const user = await Auth.signIn(email, this.password);
      this.user = user;
      console.log(user);
      return user;
    } catch (error) {
      console.log('Error signing in:', error);
      throw error;
    }
  }

  async getUserGroups() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      console.log(groups);
      return groups;
    } catch (error) {
      console.log('Error getting user groups:', error);
      throw error;
    }
  }

  getCurrentUser() {
    return this.user;
  }

  async getCurrentAuthenticatedUser () {
   const user = await Auth.currentAuthenticatedUser();
   return user;

  }


  async forgotPassword(email) {
    try {
      await Auth.forgotPassword(email);
      console.log('Password reset initiated successfully!');
    } catch (error) {
      console.log('Error initiating password reset:', error);
      throw error;
    }
  }

  async forgotPasswordSubmit(email, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      // const user = await Auth.signIn(email, newPassword);
      // this.user = user;
      console.log('Password reset successfully!');
    } catch (error) {
      console.log('Error resetting password:', error);
      throw error;
    }
  }

}

const authService = new AuthService();
export default authService;


// import { Auth } from 'aws-amplify';
// import { region, userPoolId } from '../../globalVariables';
// import { CognitoIdentityProviderClient, CreateGroupCommand } from "@aws-sdk/client-cognito-identity-provider";

// const CognitoService = {
//     createUserGroup: async (groupName, description) => {
//     debugger

//     // let credentials;

//     // try{
//     //     credentials = await Auth.currentCredentials();
//     // }
//     // catch(error){
//     //     console.log(error);
//     // }
//     const client = new CognitoIdentityProviderClient({ region });
//     const params = {
//       UserPoolId: userPoolId,
//       GroupName: groupName,
//       Description: description,
//     };
  
//     try {
//       const command = new CreateGroupCommand(params);
//       const response = await client.send(command);
//       console.log(`Group ${groupName} created successfully`);
//       return response;
//     } catch (error) {
//       console.error("Error creating group:", error);
//       throw error;
//     }
//   },

//   signUp: async (email, password) => {
//     try {
//       const { user } = await Auth.signUp({
//         username: email,
//         password,
//         attributes: {
//           email,
//         },
//       });
//       console.log(user);
//       return user;
//     } catch (error) {
//       console.log('Error signing up:', error);
//       throw error;
//     }
//   },

//   confirmSignUp: async (email, confirmationCode) => {
//     try {
//       await Auth.confirmSignUp(email, confirmationCode);
//       console.log('User confirmed successfully!');
//     } catch (error) {
//       console.log('Error confirming sign up:', error);
//     }
//   },

//   signIn: async (email, password) => {
//     try {
//       const user = await Auth.signIn(email, password);
//       console.log(user);
//       return user;
//     } catch (error) {
//       console.log('Error signing in:', error);
//       throw error;
//     }
//   },

//   getUserGroups: async () => {
//     try {
//       const user = await Auth.currentAuthenticatedUser();
//       const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
//       console.log(groups);
//       return groups;
//     } catch (error) {
//       console.log('Error getting user groups:', error);
//       throw error;
//     }
//   }
// };

// export default CognitoService;
