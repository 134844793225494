import React, { useState, useEffect } from "react";
// import config from "../config/config";
// import { useAuth, getValidToken } from "./auth/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
// import "../assets/css/Subscription.css";
// import { initGA, logPageView } from "../utils/gtag"; // Import the functions
// import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook
import apiGatewayService from "../../../common/services/apiGatewayService";
import { stripePublishKey } from "../../../globalVariables";
import {
    Box,
    Container,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Typography,
    Button,
    CircularProgress,
  } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import UpdateIcon from '@mui/icons-material/Update';

const stripePromise = loadStripe(stripePublishKey);


function SubscriptionManagement() {
    // useDocumentTitle('Manage Subscription'); // Set the page title
    const [subscriptions, setSubscriptions] = useState([]);
    const [token, setToken] = useState(null);
    const [lastPaymentDates, setLastPaymentDates] = useState({});
    const [prices, setPrices] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showChangePlanModal, setShowChangePlanModal] = useState(false);

    useEffect(() => {
        // initGA(); // Initialize Google Analytics
        // logPageView(window.location.pathname); // Log the page view
        // const fetchTokenAndSubscriptions = async () => {
        //     try {
        //         const fetchedToken = await getValidToken(); // Get the valid token
        //         setToken(fetchedToken); // Set the token state
        //         await fetchSubscriptionDetails(fetchedToken); // Fetch subscription details using the token
        //         await fetchPrices(); // Fetch available plans
        //     } catch (error) {
        //         console.error("Error fetching token and subscription details:", error);
        //     }
        // };

        // fetchTokenAndSubscriptions(); // Call the function to fetch token and subscription
        const fetchSubscriptionDetails = async (token) => {
            try {
                // const response = await fetch(`${config.subscriptionUrl}`, {
                //     headers: {
                //         authorization: `${token}`, // Set the Authorization header with the token
                //     },
                // });

                const customerIdObj = await apiGatewayService.getStripeCustomerId();
                const customerId = customerIdObj.data.Item.customerId;
                const response = await apiGatewayService.getStripeSubscriptionDetails(customerId); //cus_QYd179rcDEq2UI
                // if (!response.ok) {
                //     throw new Error('Network response was not ok');
                // }
                // const data = await response.json();
                const data = response.data;
                console.log("Fetched subscription data: ", data);
                setSubscriptions(data.data); // Set the subscriptions state
    
                // Store last payment dates
                const paymentDates = {};
                data.data.forEach(subscription => {
                    paymentDates[subscription.id] = subscription.latest_invoice_data ? subscription.latest_invoice_data.created : null;
                });
                setLastPaymentDates(paymentDates);
                await fetchPrices(); // Fetch available plans
            } catch (error) {
                console.error("Error fetching subscription details", error);
            }
        };
        fetchSubscriptionDetails();
    }, []);

    

    const fetchPrices = async () => {
        try {
            // const response = await fetch(`${config.subscribeUrl}/products`);
            // const data = await response.json();
            const response = await apiGatewayService.getStripeProducts();
            const data = response.data;
            console.log("Prices details: ", data);
  
            setPrices(data.prices);
        } catch (error) {
            console.error("Error fetching prices", error);
        }
    };

    const handleCheckout = async (newPlanId) => {
        // try {
        //     const stripe = await stripePromise;

        //     const response = await fetch(`${config.subscribeUrl}/pay`, {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             authorization: `${token}`,
        //         },
        //         body: JSON.stringify({ priceId: newPlanId }),
        //     });

        //     const session = await response.json();
        //     const result = await stripe.redirectToCheckout({
        //         sessionId: session.id,
        //     });

        //     if (result.error) {
        //         alert(result.error.message);
        //     }
        // } catch (error) {
        //     console.error("Error initiating Stripe checkout", error);
        // }
    };

    const updateSubscription = async (token, newPlanId) => {
        try {
            // const response = await fetch(`${config.subscribeUrl}/update`, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         authorization: `${token}`, // Ensure token is included in request headers
            //     },
            //     body: JSON.stringify({
            //         subscriptionId: subscriptions[0].id,
            //         newPlanId,
            //         proration_behavior: 'create_prorations', // Ensure proration behavior is included
            //     }),
            // });
            
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }
            // const data = await response.json();
            // console.log("Updated subscription data: ", data);
            const response = await apiGatewayService.getStripeUpdateSubscription(subscriptions[0].customer, subscriptions[0].id, newPlanId,subscriptions[0].items.data[0].id);
            const data = response.data;
            console.log("Updated subscription data: ", data);
            setSubscriptions([data]);
        } catch (error) {
            console.error("Error updating subscription", error);
        }
    };

    const cancelSubscription = async () => {
        try {
            // console.log("Token", token);
            // const response = await fetch(`${config.subscriptionUrl}/cancel`, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         authorization: `${token}`,
            //     },
            //     body: JSON.stringify({ subscriptionId: subscriptions[0].id }),
            // });
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }
            // const data = await response.json();
            const response = await apiGatewayService.getStripeCancelSubscription(subscriptions[0].customer, subscriptions[0].id);
            const data = response.data;
            console.log("Canceled subscription data: ", data);
            setSubscriptions([data]);
        } catch (error) {
            console.error("Error cancelling subscription", error);
        }
    };

    const reactivateSubscription = async (token) => {
        try {
            // const response = await fetch(
            //     `${config.subscriptionUrl}/reactivate`,
            //     {
            //         method: "POST",
            //         headers: {
            //             "Content-Type": "application/json",
            //             authorization: `${token}`, // Ensure token is included in request headers
            //         },
            //         body: JSON.stringify({ subscriptionId: subscriptions[0].id }),
            //     }
            // );
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }
            // const data = await response.json();
            // console.log("Reactivated subscription data: ", data);
            // setSubscriptions([data.subscription]);
            const response = await apiGatewayService.getStripeUpdateSubscription(subscriptions[0].customer, subscriptions[0].id);
            const data = response.data;
            console.log("Updated subscription data: ", data);
            setSubscriptions([data]);

            // Retain the last payment date from the original data
            setLastPaymentDates(prevDates => ({
                ...prevDates,
                [data.subscription.id]: prevDates[data.subscription.id] || null,
            }));
        } catch (error) {
            console.error("Error reactivating subscription", error);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString('en-AU', { timeZone: 'Australia/Sydney' });
    };

    const openChangePlanModal = (subscriptionId) => {
        setSelectedPlan(subscriptionId);
        setShowChangePlanModal(true);
    };

    const handlePlanChange = (planId) => {
        console.log("current selected plan: ", planId);
        setSelectedPlan(planId);
    };

    const handleChangePlanSubmit = () => {
        if (selectedPlan) {
            updateSubscription(token, selectedPlan); // Call updateSubscription directly
            setShowChangePlanModal(false);
        }
    };

    return (
        <Container
            sx={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                //   minHeight: '100vh',
                textAlign: 'center',
            }}
        >
            <Box
                className="subscriptionForm"
                sx={{
                    padding: 4,
                    // boxShadow: 3,
                    borderRadius: 2,
                    // bgcolor: 'background.paper',
                    maxWidth: 600,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {/* <h2>Manage Your Subscription</h2> */}
                { !showChangePlanModal && (subscriptions.length > 0 ? (
                    subscriptions.map(subscription => {
                        console.log("Subscription status: ", subscription.status);
                        console.log("Subscription cancel_at_period_end: ", subscription.cancel_at_period_end);
                        console.log("Subscription canceled_at: ", subscription.canceled_at);

                        const isCancellationRequested = subscription.cancel_at_period_end && subscription.canceled_at;

                        return (
                            <Box
                                key={subscription.id}
                                className="subscription-item"
                                sx={{
                                    padding: 3,
                                    margin: 2,
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    bgcolor: 'background.paper',
                                    textAlign: 'left',
                                    maxWidth: 500,
                                    width: '100%'
                                }}
                            >
                                <Typography variant="body1">
                                    <strong>Current Plan:</strong> {subscription.items.data[0].plan.nickname} -
                                    ${subscription.items.data[0].plan.amount / 100}
                                    ({subscription.items.data[0].plan.currency.toUpperCase()})
                                    per {subscription.items.data[0].plan.interval}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Status:</strong> {isCancellationRequested ? "Cancellation Requested" : subscription.status}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Last Payment Date:</strong> {lastPaymentDates[subscription.id] ? formatDate(lastPaymentDates[subscription.id]) : 'No invoice data available'}
                                </Typography>
                                {isCancellationRequested ? (
                                    <>
                                        <Typography variant="body1"><strong>Canceled At:</strong> {formatDate(subscription.canceled_at)}</Typography>
                                        <Typography variant="body1"><strong>Subscription End Date:</strong> {formatDate(subscription.current_period_end)}</Typography>
                                    </>
                                ) : (
                                    <Typography variant="body1"><strong>Next Payment Date:</strong> {formatDate(subscription.current_period_end)}</Typography>
                                )}
                                {subscription.status === 'active' && !isCancellationRequested ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<UpdateIcon />}
                                        onClick={() => openChangePlanModal(subscription.id)}
                                        sx={{
                                            borderRadius: '10px',
                                            marginTop: '50px',
                                            height: '45px',
                                            width: '100%',
                                            color: 'white',
                                            cursor: 'pointer',
                                            backgroundColor: 'var(--button-color)',
                                            ':hover' : {
                                            backgroundColor: 'var(--button-hover-color)',
                                            //   color: 'var(--button-color)',
                                            },
                                        }}
                                    >
                                        Update Subscription
                                    </Button>
                                        {/* <Button variant="contained" color="primary" onClick={() => openChangePlanModal(subscription.id)}>
                                            Change Plan
                                        </Button> */}
                                        {/* <Button variant="contained" color="secondary" onClick={() => cancelSubscription()}>
                                            Cancel Subscription
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            color="error"
                                            startIcon={<CancelIcon />}
                                            onClick={cancelSubscription}
                                            sx={{
                                                borderRadius: '10px',
                                                marginTop: '10px',
                                                height: '45px',
                                                width: '100%',
                                                color: 'white',
                                                cursor: 'pointer',
                                                backgroundColor: 'red',
                                                ':hover' : {
                                                backgroundColor: 'var(--button-hover-color-red)',
                                                //   color: 'var(--button-color)',
                                                },
                                            }}
                                            
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={() => reactivateSubscription(token)} sx={{ mt: 2 }}>
                                        Reactivate Subscription
                                    </Button>
                                )}
                            </Box>
                        );
                    })
                ) : (
                    <CircularProgress></CircularProgress>
                ))}

                {showChangePlanModal && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        width: '400px',
                        margin: 'auto',
                        // mt: '10%',
                    }}>
                        <Typography id="modal-title" variant="h5" component="h2" sx={{ mb: 2 }}>
                            Select New Plan
                        </Typography>
                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                            <RadioGroup
                                aria-label="plan"
                                name="plan"
                                sx={{ flexDirection: 'column' }}
                                onChange={(e) => handlePlanChange(e.target.value)}
                            >
                                {prices.sort((a, b) => a.unit_amount - b.unit_amount).map((price) => (
                                    <FormControlLabel
                                        key={price.id}
                                        value={price.id}
                                        control={<Radio sx={{
                                            '&.Mui-checked': {
                                              color: 'var(--button-color)',
                                            },
                                          }} />}
                                        label={`$${(price.unit_amount / 100).toFixed(2)} / ${price.recurring.interval}`}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'center', width: '100%', mt: 2}}>
                            <Button sx = {{cursor: 'pointer', marginTop: '30px' , height: '50px', backgroundColor: 'var(--button-color)', ':hover': {backgroundColor: 'var(--button-hover-color)'}}} variant="contained" color="primary" onClick={handleChangePlanSubmit}>
                                Submit
                            </Button>
                            <Button sx = {{cursor: 'pointer',marginTop: '10px' , height: '50px', backgroundColor: 'red', ':hover': {backgroundColor: 'var(--button-hover-color-red)'}}} variant="contained" color="secondary" onClick={() => setShowChangePlanModal(false)}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Container>
    );
}

export default SubscriptionManagement;
