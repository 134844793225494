
import axios from "axios";
import { AWS_API, userPoolClientId, userPoolId } from "../../globalVariables";
import localStorageService from "./localStorage.service";
class ApiGatewayService {

    // Write a general function here to generate the config headers for the api calls to the backend

    getToken() {
        const user = localStorageService.getObject('user');
        return user?.signInUserSession?.idToken?.jwtToken ? user.signInUserSession.idToken.jwtToken : null;
        // config for getting the tokens
        // const config = {
        //     headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
        //     // params: { email: email }
        // }
    };

    getEmail() {
        const user = localStorageService.getObject('user');
        return user?.attributes?.email ? user.attributes.email  : '';
        // config for getting the tokens
        // const config = {
        //     headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
        //     // params: { email: email }
        // }
    };

    getSignUpType() {
        const signUpType = localStorageService.getObject('signUpType');
        return signUpType;
    };

    getTokenDirectly(){
        const directToken = localStorageService.getObject('directToken');
        return directToken;
    };

    getcompanyAliasFromStorage() {
        const companyAlias = localStorageService.getObject('companyAlias');
        return companyAlias;
    };
    
    async getFormConfiguration(formIdFromUrl) {
        console.log("formIdFromUrl: ", formIdFromUrl);
        // const config = {
        //     headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
        //     params: { email: email }
        // }
        const response = await axios.get(`${AWS_API}/form/${formIdFromUrl}`);
        return response;
    }

    async getDropDownData(dropDownUrl) {
        console.log("dropDownUrl: ", dropDownUrl);
        const response = await axios.get(dropDownUrl);
        return response;
    }

    async postFormData(formSubmitUrl, formObject) {
        console.log("formSubmitUrl: ", formSubmitUrl);
        const response = await axios.post(formSubmitUrl, formObject);
        return response;
    }

    async getFormsDemo() {
        console.log("Demo");
        const response = await axios.get(`${AWS_API}/demo`);
        return response;
    }

    async assignGroupToUser(email, signUpType) {
        // we have to generate proper headers with tokens for this api call later on
        console.log("Creating a group for the email: ", email);
        const postBody = {
            email: email,
            signUpType: signUpType
        };
        const response = await axios.post(`${AWS_API}/assignGroup/1`, postBody);
        console.log("response for add user group: ", response);
        return response;

    }
    
    // New APIS For Form Builder
    async postOptionsData(postData, type) {
        // we have to generate proper headers with tokens for this api call later on
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.post(`${AWS_API}/options/${type}`, postBody, {headers});
        console.log("response for post options: ", response);
        return response;
    };

    async putOptionsData(optionId, postData, type) {
        // we have to generate proper headers with tokens for this api call later on
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.put(`${AWS_API}/options/${type}/${optionId}`, postBody, {headers});
        console.log("response for put options: ", response);
        return response;
    };

    async postOptionsDataSimple(postData) {
        // we have to generate proper headers with tokens for this api call later on
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.post(`${AWS_API}/optionsSimple`, postBody, {headers});
        console.log("response for post options: ", response);
        return response;
    };
    
    async getOptionsData() {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/options/`, {headers});
        return response;
    };

    async getOptionsDataById(optionId, type) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/options/${type}/${optionId}`, {headers});
        return response;
    };

    async getOptionsDataByIdSimple(optionId) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/optionsSimple/${optionId}`, {headers});
        return response;
    };

    
    async postFieldsData(postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/fields/`, postBody, {headers});
        console.log("response for post options: ", response);
        return response;
    };

    async putFieldsData(fieldId, postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.put(`${AWS_API}/fields/${encodeURIComponent(fieldId)}`, postBody, {headers});
        console.log("response for post options: ", response);
        return response;
    };

    async getFieldsData() {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        console.log('headers: ', headers);

        const response = await axios.get(`${AWS_API}/fields/`, {headers});
        return response;
    };

    async getFieldsDataById(fieldId) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };

        const response = await axios.get(`${AWS_API}/fields/${encodeURIComponent(fieldId)}`, {headers});
        return response;
    };


    async postSectionsData(postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/sections/`, postBody, {headers});;
        console.log("response for post options: ", response);
        return response;
    };

    async putSectionsData(sectionId, postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.put(`${AWS_API}/sections/${decodeURIComponent(sectionId)}`, postBody, {headers});;
        console.log("response for post options: ", response);
        return response;
    };

    async getSectionsData() {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };

        const response = await axios.get(`${AWS_API}/sections/`, {headers});
        return response;
    };

    async getSectionsDataById(sectionId) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };

        const response = await axios.get(`${AWS_API}/sections/${encodeURIComponent(sectionId)}`, {headers});
        return response;
    };

    async postFormsData(postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };

        const response = await axios.post(`${AWS_API}/forms/`, postBody, {headers});;
        console.log("response for post options: ", response);
        return response;
    };

    async putFormsData(formId, postData) {
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };

        const response = await axios.put(`${AWS_API}/forms/${decodeURIComponent(formId)}`, postBody, {headers});;
        console.log("response for post options: ", response);
        return response;
    };


    async getFormsData() {
        console.log('get forms object');
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/forms/`, {headers});
        return response;
    };

    
    async deleteOptionsData(postData) {
        console.log("Posting options: ", postData);
        const data = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.delete(`${AWS_API}/options/`, {headers, data});;
        console.log("response for delete options: ", response);
        return response;
    };

    async deleteFieldsData(postData) {
        console.log("Posting options: ", postData);
    
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
    
        // Correct the axios.delete call
        const response = await axios.delete(`${AWS_API}/fields/`, {
            headers: headers,
            data: {postData},  // 'data' should be used here for the request body in DELETE requests
        });
    
        console.log("Response for delete fields: ", response);
        return response;
    };
    
    async deleteSectionsData(postData) {
        console.log("Posting options: ", postData);
        const data = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.delete(`${AWS_API}/sections/`, {headers, data });;
        console.log("response for delete sections: ", response);
        return response;
    };

    async deleteFormsData(postData) {
        console.log("Posting options: ", postData);
        const data = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.delete(`${AWS_API}/forms/`, {headers, data});;
        console.log("response for delete forms: ", response);
        return response;
    };

    async postPublishFormsData(postData) {
        console.log("Posting publishforms: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/publishForms`, postBody, {headers});;
        console.log("response for publish forms: ", response);
        return response;
    };

    async getPublishedFormsData() {
        console.log("gettig publishforms");
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/forms/publishedPrivate`, {headers});;
        console.log("response for publish forms: ", response);
        return response;
    };

    async getValueHelpsData(apiPath, type) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };      
        // Construct the full URL with the apiPath
        const url = `${AWS_API}/valueHelpsData/${type}/${apiPath}`;
        const response = await axios.get(url, { headers });
        return response;
    }
      

    
    async getAdminUsers() {
        const params = {
            params: {
                // userGroupName: userGroupName,
                userEmail: this.getEmail(),
                signUpType: this.getSignUpType(),
            }
        }
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/adminUsers`, {headers});
        return response;
    };

    async updateGroup(emailToChange, groupType) {

        const postBody = {
            userEmail: this.getEmail(),
            emailToChange: emailToChange,
            groupType: groupType,
            signUpType: this.getSignUpType(),
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/updateGroup`, postBody, {headers});;
        return response;
    };

    async saveForms(api, postData) {
        const postBody = {
            postData: {form: postData},
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        // const response = await axios.post(`${AWS_API}/saveForms`, postBody, {headers});
        const response = await axios.post(api, postBody, {headers});
        return response;
    }

    
    async getFormById(formId) {
        console.log("gettig form by id");
        const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const url = `${AWS_API}/forms/formsById/${formId}`;
        const response = await axios.get(url, {headers});
        return response;
    };

    async getCompanyAlias() {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/companyAlias/`, {headers});
        return response;
    };

    async postCompanyAlias(postData){
        console.log("Posting company alias: ", postData);
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/companyAlias/`, postBody, {headers});
        console.log("response for post company alias: ", response);
        return response;
    };

    async getCompanyLogo() {
        const headers = {
            authorization: this.getToken() ? this.getToken() : this.getTokenDirectly(),
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            // 'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/companyLogo/`, {headers});
        return response;
    };

    async postCompanyLogo(postData) {
        const postBody = {
            postData,
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/companyLogo/`, postBody, {headers});
        return response;
    };


    async getApi(){
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/apiManagement/`, {headers});
        console.log("response for get apis: ", response);
        return response;
    };

    async postApi(postData){
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.post(`${AWS_API}/apiManagement/`, postBody, {headers});
        console.log("response for post apis: ", response);
        return response;
    };

    async putApi(postData, apiId){
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.put(`${AWS_API}/apiManagement/${encodeURIComponent(apiId)}`, postBody, {headers});
        console.log("response for put apis: ", response);
        return response;
    };

    async deleteApi(name){
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.delete(`${AWS_API}/apiManagement/${name}`, {headers});
        console.log("response for delete api: ", response);
        return response;
    };

    async postFormGroups(postData) {
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
      
        console.log("Posting options: ", postData);
      
        const response = await axios.post(`${AWS_API}/forms/groups`, postBody, {headers});
        return response;
    }

    async getFormgroups(){
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/forms/groups`, {headers});
        return response;

    }

    async assignFormGroup(formId, postData){
        const postBody = {
            postData,
        };

        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/forms/groupsAssign/${formId}`, postBody, {headers});
        return response;
    }

    async getFormsByGroup(groupId){
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
          };
        const response = await axios.get(`${AWS_API}/forms/groupsGetForms/${groupId}`, {headers});
        return response;
    }

    async deleteFormGroup(groupId) {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.delete(`${AWS_API}/forms/groups/${groupId}`, {headers});
        return response;
    }

    async getFormLimits (){
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/forms/limits`, {headers});
        return response;
    }


    // Api calls specific to form reader ==============================================================
    async getCompanyLogoPublic(){
        const companyAlias = this.getcompanyAliasFromStorage();
        const headers = {
            // authorization: token,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/companyLogo/public/${companyAlias}`, {headers});
        return response;
    };


    
    async getPublishedFormsDataPublicByCompanyAlias() {
        console.log("gettig publishforms");
        const companyAlias = this.getcompanyAliasFromStorage();
        // const headers = {
        //     authorization: `${this.getToken()}`,
        //     'Content-Type': 'application/json',
        // };
        // const response = await axios.get(`${AWS_API}/getPublishedFormsDataPublicCompanyAlias`, {headers});
        const response = await axios.get(`${AWS_API}/forms/publishedPublic/${companyAlias}`);
        console.log("response for publish forms: ", response);
        return response;
    };

    async getFormByIdPublic(formId) {
        console.log("gettig form by id");
        // const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        const companyAlias = this.getcompanyAliasFromStorage();
        // const headers = {
        //     authorization: token,
        //     'Content-Type': 'application/json',
        // };
        const url = `${AWS_API}/forms/formsByIdPublic/${companyAlias}/${formId}`;
        // const response = await axios.get(url, {headers});
        const response = await axios.get(url);
        return response;
    };


    async getPublishedFormsDataPublic() {
        console.log("gettig publishforms");
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${AWS_API}/forms/publishedPublic`, {headers});;
        console.log("response for publish forms public: ", response);
        return response;
    };

    async getSubmittedFormsById(formId) {
        console.log("gettig form by id");
        const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const url = `${AWS_API}/forms/submissions/${formId}`;
        const response = await axios.get(url, {headers});
        return response;
    };

    async getSubmittedFormsByIdAndSubmittedFormId(formId, submittedFormId){
        console.log("gettig form by id");
        const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const params = {
            submittedFormId: submittedFormId,
        };
        const url = `${AWS_API}/forms/submitted/${formId}/${submittedFormId}`;
        const response = await axios.get(url, {headers, params});
        return response;
    }

    async getSubmittedFormsByIdAndSubmittedFormIdPublic(formId, submittedFormId){
        console.log("gettig form by id");
        const companyAlias = this.getcompanyAliasFromStorage();
        console.log('companyAlias: ', companyAlias);
        // const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        // const headers = {
        //     authorization: token,
        //     'Content-Type': 'application/json',
        // };
        // const params = {
        //     submittedFormId: submittedFormId,
        // };
        const url = `${AWS_API}/forms/submittedPublic/${companyAlias}/${formId}/${submittedFormId}`;
        const response = await axios.get(url);
        return response;
    }

    

    async getValueHelpsDataPublic(apiPath, type) {
        // const token = this.getToken() ? this.getToken() : this.getTokenDirectly();
        const companyAlias = this.getcompanyAliasFromStorage();
        // const headers = {
        //     authorization: token,
        //     'Content-Type': 'application/json',
        // };      
        // Construct the full URL with the apiPath
        const url = `${AWS_API}/valueHelpsData/public/${companyAlias}/${apiPath}/${type}`;
        // const response = await axios.get(url, { headers });
        const response = await axios.get(url);
        return response;
    }


    async saveFormsPublic(formId, postData) {
        console.log("Inside save forms public!");
        const postBody = {
            postData: {form: postData},
        };
        const companyAlias = this.getcompanyAliasFromStorage();
        // const headers = {
        //     authorization: `${this.getToken()}`,
        //     'Content-Type': 'application/json',
        // };
        // const response = await axios.post(api, postBody, {headers});
        const response = await axios.post(`${AWS_API}/forms/savePublic/${companyAlias}/${formId}`, postBody);
        return response;
    }


    // Api calls for stripe=============================================================
    async getStripeProducts() {
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const params = {};
        const response = await axios.get(`${AWS_API}/stripeProducts`, {params, headers});
        return response;
    };

    async postStripeSubscribe(priceId) {
        const postBody = {
            userEmail: this.getEmail(),
            priceId: priceId
        };
        const response = await axios.post(`${AWS_API}/stripeSubscribe`, postBody);;
        return response;
    };

    async getStripeCustomerId(){
        const params = {
                email: this.getEmail(),
                option: 'customerId'
        };
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/stripeManage`, {}, {params, headers});
        // const response = await axios.post(`${AWS_API}/stripeManage/customerId/${customerId}/option/${'details'}`);
        return response; 

    };

    async getStripeSubscriptionDetails(customerId){
        const params = {
                customerId: customerId,
                option: 'details'
        };
        
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${AWS_API}/stripeManage`, {}, {params, headers});
        // const response = await axios.post(`${AWS_API}/stripeManage/customerId/${customerId}/option/${'details'}`);
        return response; 

    };

    async getStripeCancelSubscription(customerId,subscriptionId){
        // Request body
        const postBody = {
            subscriptionId: subscriptionId
        };
    
        // Query parameters
        const params = {
            customerId: customerId,
            option: 'cancel'
        };
    
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
    
        // Make the Axios POST request
        const response = await axios.post(`${AWS_API}/stripeManage`, postBody, {
            params: params,
            headers: headers
        });
  
        return response; 

    };

    async getStripeUpdateSubscription(customerId,subscriptionId, newPlanId, subscriptionItemId) {
        // Request body
        const postBody = {
            subscriptionId: subscriptionId,
            subscriptionItemId:  subscriptionItemId,
            newPlanId: newPlanId,
            proration_behavior: 'create_prorations',
        };
    
        // Query parameters
        const params = {
            customerId: customerId,
            option: 'update'
        };
    
        // Headers
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
    
        // Make the Axios POST request
        const response = await axios.post(`${AWS_API}/stripeManage`, postBody, {
            params: params,
            headers: headers
        });
  
        return response; 

    };

    async getStripeReactivateSubscription(customerId,subscriptionId) {
        // Request body
        const postBody = {
            subscriptionId: subscriptionId,
        };
    
        // Query parameters
        const params = {
            customerId: customerId,
            option: 'reactivate'
        };
    
        // Headers
        const headers = {
            authorization: `${this.getToken()}`,
            appclientid: userPoolClientId, 
            userpoolid: userPoolId,
            'Content-Type': 'application/json',
        };
    
        // Make the Axios POST request
        const response = await axios.post(`${AWS_API}/stripeManage`, postBody, {
            params: params,
            headers: headers
        });
  
        return response; 

    };

}

const apiGatewayService = new ApiGatewayService();
export default apiGatewayService;