class ValidationService {
  constructor(object) {
    this.object = object;
  }

  // Helper function to check if a value is empty
  static isObjectEmpty(value) {
    return value === null || value === undefined || 
           (typeof value === 'string' && value.trim() === '') || 
           (typeof value === 'string' && value.trim() === "") || 
           (typeof value === 'object' && Object.keys(value).length === 0);
  }

  // Method to check if the object or any of its children keys are empty
  hasEmptyKeys(obj = this.object) {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object, or if it's null, return false because we can't check keys.
      return false;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // Check if the current value is empty
        if (ValidationService.isObjectEmpty(value)) {
          return true;
        }

        // If the current value is an object or an array, recurse
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            // If it's an array, check each element
            for (const item of value) {
              if (typeof item === 'object' && this.hasEmptyKeys(item)) {
                return true;
              } else if (ValidationService.isObjectEmpty(item)) {
                return true;
              }
            }
          } else {
            // If it's an object, recurse
            if (this.hasEmptyKeys(value)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  hasEmptyKeysWithByPassKeys(obj = this.object, bypassKeys = []) {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object, or if it's null, return false because we can't check keys.
      return false;
    }
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // If the key is in the bypassKeys list, continue to the next iteration   
        if (bypassKeys.includes(key)) {
          continue;
        }
  
        const value = obj[key];
  
        // Check if the current value is empty
        if (ValidationService.isObjectEmpty(value)) {
          return true;
        }
  
        // If the current value is an object or an array, recurse
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            // If it's an array, check each element
            for (const item of value) {
              if (typeof item === 'object' && this.hasEmptyKeysWithByPassKeys(item, bypassKeys)) {
                return true;
              } else if (ValidationService.isObjectEmpty(item)) {
                return true;
              }
            }
          } else {
            // If it's an object, recurse
            if (this.hasEmptyKeys(value, bypassKeys)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
};


const validationService = new ValidationService();
export default validationService;