import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  InputLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ApiIntegrationSelector = ({ isOpen, handleClose, submitSelection, apiIntegration }) => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [availableIntegrations, setAvailableIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const typeOptions = ['API Endpoint', 'Webhook'];

  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getApi();
        console.log("response: ", response);
        const integrationsMapped = response.data.map((integration) => integration.value);
        setAvailableIntegrations(integrationsMapped);
      } catch (error) {
        console.error("Error fetching integrations:", error);
      }
      setIsLoading(false);
    };

    fetchIntegrations();
    if(apiIntegration!==null){
        setType(apiIntegration.type);
        setSelectedIntegration(apiIntegration.key);
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleIntegrationChange = (event) => {
    setSelectedIntegration(event.target.value);
  };

  const handleSubmit = () => {
    const selection = {
      type,
      key: selectedIntegration
    };
    submitSelection(selection);
    handleClose();
  };

  const filteredIntegrations = availableIntegrations.filter((integration) =>
    integration.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="custom-url-selection-title"
      aria-describedby="custom-url-selection-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <h2>Integrations</h2>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </Box>
        ) : (
          <>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              sx={{
                marginBottom: '16px',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />

            <InputLabel sx={{ marginBottom: '8px' }}>Type</InputLabel>
            <Select
              fullWidth
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
            >
              <MenuItem value="" disabled>
                <em>Select type</em>
              </MenuItem>
              {typeOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ marginBottom: '8px' }}>Integration</InputLabel>
            <Select
              fullWidth
              value={selectedIntegration}
              onChange={handleIntegrationChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
            >
              <MenuItem value="" disabled>
                <em>Select integration</em>
              </MenuItem>
              {filteredIntegrations.map((integration, index) => (
                <MenuItem key={index} value={integration.name}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ApiIntegrationSelector;