import React, { useEffect } from 'react';
import './Modal.css'; // Import CSS for modal styles

const Modal = ({ isOpen, closeModal, saveValue, inputValue, setInputValue }) => {

  useEffect(()=>{
    console.log("Modal Props: ", isOpen)

  }, [isOpen])

  // const inputRef = React.useRef(null);

  const saveValueModal = () => {
    // const inputValue = inputRef.current.value;
    saveValue(inputValue);
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            {/* <h2>Check Form</h2> */}
            <form id="popupForm">
              <label htmlFor="inputValue">Enter a Value:</label><br />
              <input type="text" id="inputValue" name="inputValue" value={inputValue} onChange={(e) => setInputValue(e.target.value)} /><br /><br />
              <button className = 'Button' type="button" onClick={saveValueModal}>Save</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
