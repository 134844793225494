import React, { useState, useEffect } from 'react';
import errorIcon from '../../common/images/errorIcon.webp';
import classes from './Error.module.css';

const Error = (props) => {
    const [error, setError] = useState('');

    useEffect(() => {
        setError(props.error); // Update error state when props.error changes
    }, [props.error]); // Add props.error to the dependency array

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {error && <img className={classes.ErrorLogo} src={errorIcon} alt="Error Icon" />}
            {error && <p className={classes.ErrorMessage}>{error}</p>}
        </div>
    );
};

export default Error;
