import React from 'react';
import classes from './LandingPage.module.css';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleCreateForm = () => {
    console.log('Create Form button clicked');
    navigate('/formBuilder');
  };

  const handleEditForm = () => {
    console.log('Edit Form button clicked');
  };

  const handleViewForm = () => {
    console.log('Edit View button clicked');
    navigate('/viewForms');
  };

  return (
    <div className={classes.FormSelectionContainer}>
      <div>
        <h1>
            Freeform Citras
        </h1>
        <hr></hr>
      </div>
      <button className={classes.Button} onClick={handleCreateForm}>Create Form</button>
      <button className={classes.Button} onClick={handleEditForm}>Edit Form</button>
      <button className={classes.Button} onClick={handleViewForm}>View Form</button>
    </div>
  );
};

export default LandingPage;
