import React, { useState, useEffect } from 'react';
import { 
  Box, Button, Grid, TextField, Typography, Dialog, DialogActions, 
  DialogContent, DialogTitle, Container, InputAdornment, MenuItem, CircularProgress
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ApiIcon from '@mui/icons-material/Api';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
// import logo from './orange2.jpg';
import logo from './api-new.webp';
import apiGatewayService from '../../common/services/apiGatewayService';
import CircularLoader from '../Loader/Loader';

const Integrations = () => {
  const [apiIntegrations, setApiIntegrations] = useState([]);
  const [filteredIntegrations, setFilteredIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    authenticationType: '',
    createdBy: '',
    editedBy: '',
  });
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailPassword, setIsEmailPassword] = useState(false);
  const [apiError, setApiError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isAuthenticationNone, setIsAuthenticationNone] = useState(false);

  const authenticationTypeOptions = ['Basic', 'API Key', 'HMAC', 'Bearer','None'];

  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getApi();
        console.log("response: ", response);
        const integrationsMapped = response.data.map((I) => I.value);
        setApiIntegrations(integrationsMapped);
        setFilteredIntegrations(integrationsMapped);
      } catch (error) {
        console.log("error in fetching apis: ", error);
      }
      setIsLoading(false);
    };

    fetchIntegrations();
  }, []);

  const getAllApis = async () => {
    setIsLoading(true);
    try {
      const response = await apiGatewayService.getApi();
      console.log("response: ", response);
      const integrationsMapped = response.data.map((I) => I.value);
      setApiIntegrations(integrationsMapped);
      setFilteredIntegrations(integrationsMapped);
    } catch (error) {
      console.log("error in fetching apis: ", error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    const filtered = apiIntegrations.filter(integration => 
      integration.name.includes(value) ||
      integration.authenticationType.includes(value) ||
      integration.createdBy.includes(value) ||
      integration.editedBy.includes(value)
    );
    setFilteredIntegrations(filtered);
  };

  const handleEditClick = (integration) => {
    setSelectedIntegration(integration);
    setIsEdit(true);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedIntegration(null);
    setApiError('');
    setEmailError('');
  };

  const validateApi = (api) => {
    const apiRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;
    return apiRegex.test(api);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSave = async () => {
    if (selectedIntegration.api && !validateApi(selectedIntegration.api)) {
      setApiError('Please enter a valid API endpoint.');
      return;
    }
    if (isEmailPassword && selectedIntegration.email && !validateEmail(selectedIntegration.email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    try {
      if (isEdit) {
        const response = await apiGatewayService.putApi(selectedIntegration, selectedIntegration.name);
        console.log("Updated response: ", response);
      } else {
        const response = await apiGatewayService.postApi(selectedIntegration);
        console.log("Created response: ", response);
      }
    } catch (error) {
      console.log("Error in saving api: ", error);
    }
    setIsLoading(false);
    handleDialogClose();
    getAllApis();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await apiGatewayService.deleteApi(selectedIntegration.name);
      console.log("Deleted response: ", response);
      handleDialogClose();
    } catch (error) {
      console.log("Error in deleting api: ", error);
    }
    setIsLoading(false);
    getAllApis();
  };

  const handleCreateNew = () => {
    setSelectedIntegration({ name: '', api: '', type: '', authenticationType: '', authenticationValue: '', createdBy: '', editedBy: '' });
    setIsAddNew(true);
    setIsDialogOpen(true);
  };

  const handleBackClick = () => {
    setIsEdit(false);
    setIsAddNew(false);
  };


  const handleAuthenticationType = (e) => {
    setSelectedIntegration({ ...selectedIntegration, authenticationType: e.target.value });
    setIsEmailPassword(e.target.value === 'Basic');
    setIsAuthenticationNone(e.target.value === 'None');
  };

  return (
    <Container  sx={{ padding: '0px', margin: 0, minWidth: '95%' }}>
      <AppBar position="static" sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: '10px', padding: '0px', color: 'white' }}>
        <Toolbar>
          <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
            <ApiIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            Integrations
          </Typography>
          {(isEdit || isAddNew) && (
            <Button
              sx={{
                width: '100px',
                marginRight: '-10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }}
              color="inherit"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={handleBackClick}
              disabled={isOnlyReadAccess}
            >
              Back
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <Box mb={2} sx={{ mt: '5px' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    {/* Filters */}
                    <TableCell>
                      <TextField
                        variant="outlined"
                        placeholder="Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        placeholder="Authentication Type"
                        name="authenticationType"
                        value={filters.authenticationType}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        placeholder="Created By"
                        name="createdBy"
                        value={filters.createdBy}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        placeholder="Edited By"
                        name="editedBy"
                        value={filters.editedBy}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Grid container spacing={2}>
            {filteredIntegrations.map((integration, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Box p={2} borderRadius={0} textAlign="center" sx = {{backgroundColor: 'var(--field-background-color-1)'}}>
                  <img src={logo} alt={integration.name} style={{ maxWidth: '50%' }} />
                  <Typography variant="h6">{integration.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditClick(integration)}
                    sx={{
                      mt: 2,
                      width: '100%',
                      backgroundColor: 'var(--button-color)',
                      color: 'white',
                      fontWeight: 'normal',
                      textTransform: 'none',
                      ":hover": {
                        backgroundColor: 'var(--button-hover-color)',
                      },
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            ))}

            {/* Create New Integration Box */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box p={2} border={0} borderRadius={0} textAlign="center" sx = {{backgroundColor: 'var(--field-background-color-1)'}}>
                <Typography variant="h6">Create New Integration</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateNew}
                  sx={{
                    mt: 2,
                    width: '100%',
                    backgroundColor: 'var(--button-color)',
                    color: 'white',
                    fontWeight: 'normal',
                    textTransform: 'none',
                    ":hover": {
                      backgroundColor: 'var(--button-hover-color)',
                    },
                  }}
                >
                  Create
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{selectedIntegration?.name ? 'Edit Integration' : 'Create New Integration'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={selectedIntegration?.name || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, name: e.target.value })}
            fullWidth
            margin="none"
            sx={{
              mt: '20px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            label="Type"
            value={selectedIntegration?.type || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, type: e.target.value })}
            fullWidth
            margin="none"
            sx={{
              mt: '20px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            label="API"
            value={selectedIntegration?.api || ''}
            onChange={(e) => {
              setSelectedIntegration({ ...selectedIntegration, api: e.target.value });
              setApiError(validateApi(e.target.value) ? '' : 'Please enter a valid API endpoint.');
            }}
            fullWidth
            margin="normal"
            error={!!apiError}
            helperText={apiError}
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            select
            label="Authentication Type"
            value={selectedIntegration?.authenticationType || ''}
            onChange={handleAuthenticationType}
            fullWidth
            margin="normal"
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          >
            {authenticationTypeOptions.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          {!isEmailPassword && (
            <TextField
              label="Authentication Value"
              value={selectedIntegration?.authenticationValue || ''}
              onChange={(e) => setSelectedIntegration({ ...selectedIntegration, authenticationValue: e.target.value })}
              fullWidth
              margin="normal"
              sx={{
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />
          )}
          {isEmailPassword && (
            <>
              <TextField
                label="Username"
                value={selectedIntegration?.userName || ''}
                onChange={(e) => {
                  setSelectedIntegration({ ...selectedIntegration, userName: e.target.value });
                  // setEmailError(validateEmail(e.target.value) ? '' : 'Please enter a valid email address.');
                }}
                fullWidth
                margin="normal"
                error={!!emailError}
                helperText={emailError}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <TextField
                label="Password"
                value={selectedIntegration?.password || ''}
                onChange={(e) => setSelectedIntegration({ ...selectedIntegration, password: e.target.value })}
                fullWidth
                margin="normal"
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
            </>
          )}
          {/* <TextField
            disabled
            label="Created By"
            value={selectedIntegration?.createdBy || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, createdBy: e.target.value })}
            fullWidth
            margin="normal"
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            disabled
            label="Edited By"
            value={selectedIntegration?.editedBy || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, editedBy: e.target.value })}
            fullWidth
            margin="normal"
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          /> */}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            onClick={handleSave}
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Save
          </Button>
          <Button
            onClick={handleDialogClose}
            sx={{
              mt: 2,
              width: '100%',
              color: 'var(--button-color)', // Set text color to white
              backgroundColor: 'transparent',
              borderColor: 'var(--button-color)',
              textTransform: 'none', // Prevent uppercase text
              ":hover": {
                borderColor: 'var(--button-color)',
              },
            }}
          >
            Cancel
          </Button>
          {isEdit && (
            <Button
              onClick={handleDelete}
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: 'var(--button-color-red)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color-red)',
                },
              }}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Integrations;