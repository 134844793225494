import React, { useState, useEffect } from "react";
// import { useAuth, getValidToken } from "./auth/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import "./Subscription.css";
import apiGatewayService from "../../../common/services/apiGatewayService";
// import config from "../config/config";
// import { initGA, logPageView } from "../utils/gtag"; // Import the functions
// import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Button,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the check circle icon
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import the bullet point icon
import { stripePublishKey } from "../../../globalVariables";
import SubscriptionManagement from "../SubscriptionManagement/SubscriptionManagement";
import CircularLoader from "../../Loader/Loader";

const stripePromise = loadStripe(stripePublishKey);

function Subscription() {
    // useDocumentTitle('RippaDay - Sign Up'); // Set the page title
    const [selectedPriceId, setSelectedPriceId] = useState("price_1POCcHHuikSJIOpoEDDKLOuK"); // Default to weekly price ID
    const [prices, setPrices] = useState([]);
    const navigate = useNavigate(); // Get the navigate function using useNavigate
    const [token, setToken] = useState(null); // Initialize token state
    const [isLoading, setIsLoading] =  useState(false);

    // useEffect(() => {
        // initGA(); // Initialize Google Analytics
        // logPageView(window.location.pathname); // Log the page view
        // const fetchToken = async () => {
        //     try {
        //         const fetchedToken = await getValidToken(); // Get the valid token
        //         setToken(fetchedToken); // Set the token state
        //     } catch (error) {
        //         console.error("Error fetching token:", error);
        //     }
        // };

        // fetchToken(); // Call the fetchToken function
    // }, []);

    useEffect(() => {
        const fetchPrices = async () => {
            setIsLoading(true);
            try {
                // const response = await fetch(`${config.subscribeUrl}/products`);
                // const data = await response.json();
                // setPrices(data.prices);
                const response = await apiGatewayService.getStripeProducts();
                const data = response.data;
                console.log("Prices details: ", data);
                setPrices(data.prices);
            } catch (error) {
                console.error("Error fetching prices", error);
            }
            setIsLoading(false);
        };
        fetchPrices();
    }, []);

    const handleCheckout = async () => {
        try {
            const stripe = await stripePromise;

            // const response = await fetch(`${config.subscribeUrl}/pay`, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         authorization: `${token}`,
            //     },
            //     body: JSON.stringify({ priceId: selectedPriceId }),
            // });

            // const session = await response.json();

            const response = await apiGatewayService.postStripeSubscribe(selectedPriceId);
            const session = response.data;
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            console.error("Error initiating Stripe checkout", error);
        }
    };

    const navigateToSignIn = () => {
        // Create a function to navigate to the sign-in route
        navigate("/members"); // Use navigate function to navigate to the sign-in route
    };

    return (
        // <div className="subscriptionContainer">
        //     <div className="subscriptionForm">
        //     <h2>Select Your Subscription Plan</h2>
        //     <div className="subscription-options">
        //         {prices
        //             .sort((a, b) => a.unit_amount - b.unit_amount)
        //             .map((price) => (
        //                 <label key={price.id}>
        //                     <input
        //                         type="radio"
        //                         name="plan"
        //                         value={price.id}
        //                         checked={
        //                             selectedPriceId === price.id
        //                         }
        //                         onChange={(e) => setSelectedPriceId(e.target.value)}
        //                     />
        //                     {`$${(price.unit_amount / 100).toFixed(2)} a ${
        //                         price.recurring.interval
        //                     }`}
        //                 </label>
        //             ))}
        //     </div>
            
        //     <button className="action-button" onClick={handleCheckout}>
        //         Proceed to Checkout
        //     </button>
        //     <br></br>
        //     <p>Subscriptions can be altered or cancelled at any time by clicking on your email or manage within the app</p>
        //     </div>
        // </div>
    // <Container maxWidth="sm" sx={{ color: '(--app-color)'}}>
    //   <Box
    //     className="subscriptionContainer"
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       minHeight: '100vh',
    //       textAlign: 'center',
    //     }}
    //   >
    //     <Box
    //       className="subscriptionForm"
    //       sx={{
    //         padding: 4,
    //         boxShadow: 3,
    //         borderRadius: 2,
    //         bgcolor: 'background.paper',
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center'
    //       }}
    //     >
    //       <Typography variant="h4" component="h2" gutterBottom>
    //         Select Your Subscription Plan
    //       </Typography>
    //       <FormControl component="fieldset">
    //         <RadioGroup
    //           name="plan"
    //           value={selectedPriceId}
    //           onChange={(e) => setSelectedPriceId(e.target.value)}
    //           sx={{ flexDirection: 'column' }}
    //         >
    //           {prices
    //             .sort((a, b) => a.unit_amount - b.unit_amount)
    //             .map((price) => (
    //               <FormControlLabel
    //                 key={price.id}
    //                 value={price.id}
    //                 control={<Radio sx={{ 
    //                       color: 'var(--button-color)',
    //                       '&.Mui-checked': {
    //                       color: 'var(--button-color)',
    //                     }, }} />}
    //                 label={`$${(price.unit_amount / 100).toFixed(2)} a ${price.recurring.interval}`}
    //               />
    //             ))}
    //         </RadioGroup>
    //       </FormControl>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         onClick={handleCheckout}
    //         sx={{
    //           width: '100%',
    //           mt: 2,
    //           backgroundColor: 'var(--button-color)',
    //           '&:hover': {
    //             backgroundColor: 'var(--button-hover-color)',
    //           },
    //         }}
    //       >
    //         Proceed to Checkout
    //       </Button>
    //       {/* <Typography variant="body2" sx={{ mt: 2 }}>
    //         Subscriptions can be altered or cancelled at any time by clicking on your email or manage within the app.
    //       </Typography> */}
    //     </Box>
    //   </Box>
    // </Container>
    <Container sx={{ color: 'var(--app-color)', marginTop: '20px', padding: 0 , minWidth: '95%' }}>
      {isLoading && <CircularLoader></CircularLoader>}
      { !isLoading && <Box
        className="subscriptionContainer"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          className="subscriptionForm"
          sx={{
            padding: 4,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Subscriptions
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'center' }}>
            {prices
              .sort((a, b) => a.unit_amount - b.unit_amount)
              .map((price) => (
                <Button
                  key={price.id}
                  variant={selectedPriceId === price.id ? 'contained' : 'outlined'}
                  onClick={() => setSelectedPriceId(price.id)}
                  sx={{
                    color: 'black',
                    borderRadius: '10px',
                    height: '320px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: 2,
                    textTransform: 'none',
                    width: '100%',
                    border: `1px solid var(--button-color)`,
                    '&:hover': {
                      backgroundColor: 'var(--button-color)',
                      transform: 'scale(1.07)',
                      transition: 'all 0.3s ease',
                      color: 'white'
                    },
                    bgcolor: selectedPriceId === price.id ? 'var(--button-color)' : 'background.paper',
                    color: selectedPriceId === price.id ? '#fff' : 'inherit',
                  }}
                >
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    {price.nickname}
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1}}>
                    ${((price.unit_amount / 100).toFixed(2))}
                  </Typography>
                  <Typography variant="body2">
                    /{price.recurring.interval}
                  </Typography>
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          position: 'absolute', // To push the footer to the bottom
                          bottom: 16, // Adjust this value as needed
                          left: 16, // Adjust this value as needed
                          right: 16, // Adjust this value as needed
                          gap: 1,
                      }}>
                          {['Build Forms', 'View Forms', 'Submit Forms', 'Technical Support'].map((text) => (
                              <Box key={text} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <CheckCircleIcon sx={{ fontSize: 16, color: 'blue', backgroundColor: 'white', borderRadius: '20px'  }} />
                                  <Typography sx ={{fontSize: '12px'}}>
                                      {text}
                                  </Typography>
                              </Box>
                          ))}
                      </Box>
                </Button>
              ))}
          </Box>
          <Button
            variant="contained"
            onClick={handleCheckout}
            sx={{
              mt: 2,
              height: '50px',
              backgroundColor: 'var(--button-color)',
              '&:hover': {
                backgroundColor: 'var(--button-hover-color)',
                // transform: 'scale(1.05)',    
              },
              width: '100%',
            }}
          >
            Proceed to Checkout
          </Button>
        </Box>
      </Box> }
    </Container>    

);
}

export default Subscription;
