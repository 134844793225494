import React, { useEffect, useState } from 'react';
import SectionBuilder from '../SectionBuilder/SectionBuilder';
import classes from './FormSectionsBuilder.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import Modal from '../Modal/Modal';
import SelectionModalComponent from '../SelectionModal/SelectionModal';
import SectionsSelector from '../SectionsSelector/SectionsSelector';
import validationService from '../../../common/validationService.service';
import localStorageService from '../../../common/services/localStorage.service';
import utilityFunctions from '../../../common/services/utilityFunctions';
import { AWS_API } from '../../../globalVariables';
import FormAdvanceFeatures from '../../FormAdvanceFeatures/FormAdvanceFeatures';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, FormGroup, Checkbox, InputLabel } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Button, Tooltip } from "@mui/material";
import { TextField } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import BackupIcon from '@mui/icons-material/Backup';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
// import ApiIntegrationSelector from '../ApiIntegrationSelector/ApiIntegrationSelector'; // Import the ApiIntegrationSelector component

import ApiIntegrationSelector from '../../ApiIntegrationSelector/ApiIntegrationSelector';

const initialFeatures = [
  { name: 'Send Forms Via Email', id: 'email', checked: false, placeHolderText: 'enter email' },
  { name: 'Generate PDF View', id: 'pdf', checked: false, placeHolderText: 'enter text' },
  { name: 'Custom Submission URL', id: 'submit', checked: false, placeHolderText: 'enter submission url' }
];

const FormSectionsBuilder = ({ auth, form, handleClose, isNew, isEditInput, formIdInput }) => {
  const [sectionsArray, setSectionsArray] = useState([]);
  const [formHeaders, setFormHeaders] = useState({ID: '', FormName: ''});
  const [submitAPI, setSubmitAPI] = useState('');
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [isSubmissionURL, setIsSubmissionURL] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [advanceFeatures, setAdvanceFeatures] = useState(initialFeatures);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [emailToSend, setEmailToSend] = useState('');
  const [sectionSelectorOpen, setSectionSelectorOpen] = useState(false);
  const [isURLModalOpen, setIsURLModalOpen] = useState(false); // State for ApiIntegrationSelector modal

  const [isOpen, setIsOpen] = useState(false);  
  const [inputValue, setInputValue] = useState('');
  const [cogntioUser, setCogntioUser] = useState(null);
  const [apiIntegration, setApiIntegration] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    console.log("GOT THE AUTH HERE!!!!!!. Now let us check the user group name");
    if (form?.formHeaders) {
      setIsEdit(true);
      setFormHeaders(form.formHeaders);
      setSectionsArray(form.sections);
      setSubmitAPI(form.submitApi);
      if (form?.isEmail) {
        selectedOptions['email'] = true;
        setIsEmail(form.isEmail);
      }
      if (form?.emailToSend) {
        setEmailToSend(form.emailToSend);
      }
      if (form?.apiIntegration){
        selectedOptions['submit'] = true;
        setApiIntegration(form?.apiIntegration);
      }

      if (isEditInput){
        setIsEdit(true);
        setFormId(formIdInput);
      }
    }

    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    console.log("currentCognito User: ", user);
  }, [auth]);

  const handleAdvanceFeatureSelection = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: checked,
    }));
    setFormAdvanceFeaturesSelection(name, checked);
  };

  const setFormAdvanceFeaturesSelection = (id, checked) => {
    if (id === 'email') {
      setIsEmail(checked);
    } else if (id === 'submit') {
      setIsSubmissionURL(checked);
      // if (checked) {
      //   setIsURLModalOpen(true); // Open the modal when Custom Submission URL is checked
      // } else {
      //   setSubmitAPI(''); // Reset the submit API when unchecked
      // }
    }
  };

  const handleAddSection = () => {
    setSectionsArray([...sectionsArray, { title: "", fields: "" }]);
  };

  const updateSectionsArray = (section, index) => {
    if (section === undefined) {
      let newSectionsArray = Array.from(sectionsArray);
      newSectionsArray = newSectionsArray.filter((_, ind) => ind !== index);
      setSectionsArray(newSectionsArray);
    } else {
      sectionsArray[index] = section;
    }
  };

  const checkFormHeaders = () => {
    let headerCheck = true;
    Object.values(formHeaders).forEach((value) => {
      if (value === "") {
        headerCheck = false;
      }
    });
    return headerCheck;
  };

  const handleSubmitForm = async () => {
    if (!checkFormHeaders()) {
      alert('Headers can not be empty');
    } else {
      const formToSubmit = generateFormForSubmission();
      if (formToSubmit !== false) {
        const formObject = {
          displayName: formHeaders.ID,
          sections: formToSubmit
        };
        if (validationService.hasEmptyKeys(formToSubmit)) {
          alert('Form keys can not be empty!');
        } else {
          try {
            console.log("formToSubmit: ", formToSubmit);
            console.log("isEdit: ", isEdit);
            console.log("formId: ", formId);
            let response;
            if (isEdit){
              response = await apiGatewayService.putFormsData(formId, formObject);
            } else {
              response = await apiGatewayService.postFormsData(formObject);
            }
            console.log(response);
            if (handleClose) {
              handleClose();
            }
          } catch (error) {
            console.log("Error in submitting form: ", error);
            if (error.response) {
              alert('Can not post form: ' + error.response.data.message);
            }
          }
        }
      }
    }
  };

  const generateFormForSubmission = () => {
    if ((submitAPI !== '' || submitAPI !== 'default') && isSubmissionURL) {
      const isValid = isValidUrl(submitAPI);
      if (!isValid) {
        alert("Please enter valid url for submission!");
        return false;
      }
    }

    if ((emailToSend !== '' && isEmail)) {
      const isValidEmail = validateEmail(emailToSend);
      if (!isValidEmail) {
        alert("Please enter valid email address for sending printed forms!");
        return false;
      }
    }

    const date = formatDate(new Date());
    formHeaders['configMetaData'] = {
      CreatedOn: date,
      CreatedBy: cogntioUser.attributes.email.toUpperCase(),
      LastEditedOn: date,
      LastEditedBy: cogntioUser.attributes.email.toUpperCase(),
      Status: "Active"
    };
    const formToSubmit = {
      formHeaders: formHeaders,
      sections: sectionsArray,
      submitApi: getDefaultSubmitApi(),
    };
    if (isEmail) {
      formToSubmit['isEmail'] = isEmail;
    }
    if (emailToSend !== '' && isEmail) {
      formToSubmit['emailToSend'] = emailToSend;
    }

    if (isNew) {
      formToSubmit['publishStatus'] = 'saved';
    }

    //Add integrationconfig into form. Is Submission URL basicaly controls the api integration
    if (isSubmissionURL && apiIntegration!==null) {
      formToSubmit['apiIntegration'] = apiIntegration;
    }
    return formToSubmit;
  };

  const getDefaultSubmitApi = () => {
    return `${AWS_API}/forms/save/${formHeaders.ID}`;
  };

  function formatDate(date) {
    const pad = (number, length = 2) => String(number).padStart(length, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3) + '000000';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const addOptionsIntoField = (section) => {
    if (section === undefined) {
      handleAddSection();
    } else {
      sectionsArray.push(section);
      const newFieldsArray = Array.from(sectionsArray);
      setSectionsArray(newFieldsArray);
    }
  };

  const updateEmailToSend = (event) => {
    setEmailToSend(event.target.value)
  };

  const updateSubmitURL = (event) => {
    setSubmitAPI(event.target.value);
  };

  const handleAddSubmitURL = (value) => {
    setIsSubmissionURL(value);
    if (!value) {
      setSubmitAPI('');
    }
  };

  function isValidUrl(url) {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return urlPattern.test(url);
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleMoveSectionUp = (index) => {
    if (index > 0) {
      const newFieldsArray = [...sectionsArray];
      [newFieldsArray[index - 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index - 1]];
      setSectionsArray(newFieldsArray);
    }
  };

  const handleMoveSectionDown = (index) => {
    if (index < sectionsArray.length - 1) {
      const newFieldsArray = [...sectionsArray];
      [newFieldsArray[index + 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index + 1]];
      setSectionsArray(newFieldsArray);
    }
  };

  const handleOpenSectionSelector = () => {
    setSectionSelectorOpen(true);
  };

  const handleCustomURLSubmit = (selection) => {
    console.log(selection);
    // setSubmitAPI(selection.selectedIntegration);
    setApiIntegration(selection);
    setIsURLModalOpen(false); // Close the modal after submission
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSectionSelectorOpen(false);
  };

  const saveModalValue = () => {
    if(inputValue!==""){
        const storedValue = inputValue;
        formHeaders[storedValue] = '';
    };
    setInputValue("");
    closeModal();
  };

  const handleHeaderInputChange = (e) => {
    formHeaders[e.target.name] = e.target.value;
    setFormHeaders({...formHeaders});
  };

  return (
    <div className={classes.FormSectionsContainer}>
      <div className={classes.HeaderContainer}><h2 style={{ padding: '0px', margin: '0px' }}>Form Builder</h2></div>
      
      <div>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Headers
        </Typography>
        <Modal isOpen={isOpen} closeModal={closeModal} saveValue={saveModalValue} inputValue={inputValue} setInputValue={setInputValue} />
        <div className={classes.FormFieldContainer}>
          {Object.keys(formHeaders).map((key, index) => (
            <div className={key === 'configMetaData' ? classes.FieldEmpty : classes.Field} key={index}>
              {key !== 'configMetaData' && (
                <>
                  <InputLabel sx={{ mt: 1, mb: 1 }}>{key}</InputLabel>
                  <TextField
                    type="text"
                    name={key}
                    value={formHeaders[key]}
                    onChange={handleHeaderInputChange}
                    variant="outlined"
                    size="medium"
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: 'white'
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 40,
                        width: '100%',
                      },
                    }}
                  />
                </>
              )}
            </div>
          ))}
          <Tooltip title="Add More Headers">
            <Button
              onClick={openModal}
              sx={{
                mt: '35px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--button-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: 'var(--button-color)',
                },
                height: '58px'
              }}
            >
              <AddIcon sx={{ fontSize: 45, marginTop: 1, marginBottom: '5px', color: 'var(--button-color)', padding: 0 }} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <FormControl component="fieldset">
        <FormGroup>
          {advanceFeatures.map((option) => (
            <Box key={option.id} sx={{ marginBottom: 0, marginLeft: '0px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions[option.id] || false}
                      onChange={handleAdvanceFeatureSelection}
                      name={option.id}
                    />
                  }
                  label={option.name}
                  sx={{ marginRight: 1, flexGrow: 1 }}
                />
                {option.id === 'submit' && selectedOptions[option.id] && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsURLModalOpen(true)}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'var(--button-color)',
                      ':hover': {
                        backgroundColor: 'var(--button-hover-color)',
                      },
                    }}
                  >
                    Set URL
                  </Button>
                )}
              </Box>
              {selectedOptions[option.id] && (
                <Box sx={{ marginTop: 0 }}>
                  {option.id === 'email' && (
                    <TextField
                      type="email"
                      name={option.id}
                      placeholder={option.placeHolderText}
                      onChange={(e) => updateEmailToSend(e)}
                      value={emailToSend}
                      variant="outlined"
                      size="medium"
                      sx={{
                        width: '100%',
                        "& .MuiInputBase-root": {
                          height: 35,
                        },
                        "& .MuiOutlinedInput-input": {
                          height: 35,
                          width: '100%',
                        },
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          ))}
        </FormGroup>
      </FormControl>



      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Sections
      </Typography>
      {sectionsArray.map((section, index) => (
        <div className={classes.fieldContainer} key={index}>
          <div className={classes.ButtonContainerDrag}>
            <Tooltip title="Move Up">
              <Button
                onClick={() => handleMoveSectionUp(index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                  ':hover': {
                    backgroundColor: 'transparent',
                    color: 'var(--button-hover-color)',
                  },
                  height: '30px'
                }}
              >
                <ArrowDropUpIcon sx={{ fontSize: 40, marginTop: 0, marginBottom: '10px', color: 'var(--app-color)' }} />
              </Button>
            </Tooltip>
            <Tooltip title="Move Down">
              <Button
                onClick={() => handleMoveSectionDown(index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                  ':hover': {
                    backgroundColor: 'transparent',
                    color: 'var(--button-hover-color)',
                  },
                  height: '30px'
                }}
              >
                <ArrowDropDownIcon sx={{ fontSize: 40, marginTop: 0, marginBottom: '10px', color: 'var(--app-color)' }} />
              </Button>
            </Tooltip>
          </div>
          <SectionBuilder
            key={index}
            indexSectionsArray={index}
            section={{ ...section }}
            updateSectionsArray={updateSectionsArray}
            title={section.title}
            isForm={true}
          />
        </div>
      ))}

      <div className={classes.SectionsButtonContainer}>
        <Button
          onClick={handleAddSection}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            background: 'transparent',
            color: 'var(--icon-button-color-grey)',
            cursor: 'pointer',
            borderRadius: '10px',
            ':hover': {
              backgroundColor: '#F0F4F4',
              color: 'var(--icon-button-color-grey)',
            },
            height: '58px'
          }}
        >
          <AddBoxIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-grey)', padding: 0 }} />
          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Section</Typography>
        </Button>
        <Button
          onClick={handleOpenSectionSelector}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            background: 'transparent',
            color: 'var(--icon-button-color-grey)',
            cursor: 'pointer',
            borderRadius: '10px',
            ':hover': {
              backgroundColor: '#F0F4F4',
              color: 'var(--icon-button-color-grey)',
            },
            height: '58px'
          }}
        >
          <ReorderIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-grey)', padding: 0 }} />
          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Select Section</Typography>
        </Button>
      </div>

      {
        !isOnlyReadAccess &&
        <SectionsSelector
          isOpen={sectionSelectorOpen}
          handleClose={closeModal}
          submitOption={addOptionsIntoField}
        />
      }
      {!isOnlyReadAccess && <button className={classes.Button} onClick={handleSubmitForm}>Save Form</button>}

      {/* ApiIntegrationSelector Modal */}
      <ApiIntegrationSelector
        isOpen={isURLModalOpen}
        handleClose={() => setIsURLModalOpen(false)}
        submitSelection={handleCustomURLSubmit}
        apiIntegration = {apiIntegration || form?.apiIntegration || null}
      />
    </div>
  );
};

export default FormSectionsBuilder;