import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiGatewayService from '../../common/services/apiGatewayService'; // Adjust the import path as necessary

const FormLimits = ({ isSettings }) => {
  const [maxForms, setMaxForms] = useState(0);
  const [maxSubmissions, setMaxSubmissions] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [isSettingsMode, setIsSettingsMode] = useState(false); // Internal state for settings mode

  const navigate = useNavigate();

  useEffect(() => {
    // Set internal state based on the isSettings prop
    if (isSettings) {
      setIsSettingsMode(true);
    }

    const fetchFormLimits = async () => {
      try {
        const response = await apiGatewayService.getFormLimits();
        console.log("response for get limits: ", response);
        const data = response.data[0];
        setMaxForms(data.maxForms);
        setMaxSubmissions(data.maxSubmissions);
        setTotalForms(data.totalForms);
        setTotalSubmissions(data.totalSubmissions);
      } catch (error) {
        console.error('Error fetching form limits:', error);
      }
    };

    fetchFormLimits();
  }, [isSettings]); // Add isSettings to the dependency array

  const handleUpgradeClick = () => {
    navigate('/sideMenu/#subscription'); // Adjust the path to your Subscriptions component
  };

  // Conditional styles based on isSettingsMode state
  const boxStyles = isSettingsMode ? { p: 0, m: 0, mt: 1 } : { p: 10, m: 5, mt: 0 };
  const typographyVariant = isSettingsMode ? 'h5' : 'h2';
  const subHeadingVariant = isSettingsMode ? 'h7' : 'h3';
  const upgradeButtonJustify = isSettings ? 'right' : 'left';

  return (
    <Box sx={{ backgroundColor: 'none', ...boxStyles }}>
      { !isSettings && (<Box>
      <Typography variant={'h2'} sx = {{mb: 10}}> 
         {'Form Limits: '}
      </Typography>
      </Box>)}
      <Grid container spacing={4} alignItems="center">
        {/* Responsive Grid layout: xs = 12 (full width on small screens), md = 6 (half width on medium and up) */}
        <Grid item xs={12} md={6}>
          <Box textAlign="left">
            <Typography variant={typographyVariant}>
              {totalSubmissions}/{maxSubmissions}
            </Typography>
            <Typography variant={subHeadingVariant}>Submissions</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="left">
            <Typography variant={typographyVariant}>
              {totalForms}/{maxForms}
            </Typography>
            <Typography variant={subHeadingVariant}>Forms</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={10} display="flex" justifyContent= {upgradeButtonJustify}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleUpgradeClick}
          sx={{
            mt: 2,
            backgroundColor: 'var(--button-color)',
            color: 'white', // Set text color to white
            fontWeight: 'normal',
            textTransform: 'none', // Prevent uppercase text
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Upgrade
        </Button>
      </Box>
    </Box>
  );
};

export default FormLimits;