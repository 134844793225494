import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SectionDependantSelector = ({ isOpen, handleClose, submitDependant, removeDependant, dependant }) => {
  const [availableSections, setAvailableSections] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);
  const [selectedSection, setSelectedSection] = useState(''); // Changed to useState
  const [selectedField, setSelectedField] = useState(''); // Changed to useState
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch all sections for the dropdown
    const getAllSections = async () => {
      try {
        const response = await apiGatewayService.getSectionsData();
        setAvailableSections(response.data);
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };
    getAllSections();
  }, []);

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getSectionsDataById(sectionId);
      setCurrentSection({ ...response.data });
      setAvailableFields(Array.from(response.data.fields)); // Assuming fields are part of the section data
    } catch (error) {
      console.error('Error getting section:', error);
    }
    setIsLoading(false);
  };

  const handleSectionChange = (event) => {
    const sectionId = event.target.value;
    // setSelectedSection(sectionId);
    getSectionById(sectionId);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
  };

  useEffect(() => {
    if (dependant && dependant.title && dependant.field) {
      setSelectedSection(dependant.title);
      setSelectedField(dependant.field);
      setAvailableFields([{name: dependant.field}]);
      //getSectionById(dependant.sectionId);
    }
  }, [dependant]);

  const handleSubmit = () => {
    if (currentSection && selectedField) {
      const field = selectedField;
      const title = currentSection.title;
      submitDependant(title, field);
    }
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="section-dependant-selector-title"
      aria-describedby="section-dependant-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px', // Adjust the width as needed
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography id="section-dependant-selector-title" variant="h6" component="h2" gutterBottom>
          Select Dependant Section and Field
        </Typography>

        <InputLabel sx={{ mt: 2 }}>Sections</InputLabel>
        <Select
          fullWidth
          value={selectedSection}
          onChange={handleSectionChange}
          displayEmpty
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
        >
          <MenuItem value="" disabled>
            <em>Select a section</em>
          </MenuItem>
          {availableSections.map((section, index) => (
            <MenuItem key={index} value={section.sectionId}>
              {section.sectionName}
            </MenuItem>
          ))}
        </Select>

        <InputLabel sx={{ mt: 2 }}>Fields</InputLabel>
        <Select
          fullWidth
          value={selectedField}
          onChange={handleFieldChange}
          displayEmpty
          disabled={!selectedSection || isLoading}
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
        >
          <MenuItem value="" disabled>
            <em>Select a field</em>
          </MenuItem>
          {availableFields.map((field, index) => (
            <MenuItem key={index} value={field.name}>
              {field.name}
            </MenuItem>
          ))}
        </Select>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedSection || !selectedField} // Disable button if either section or field is not selected
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={removeDependant}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SectionDependantSelector;