// src/StripeContext.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51PfvWERxkGGIIuhGGSHlRegKanPvzxEqhQ5jG8HSmyngbPUAHXHMXmK5gtrqhntPDse4dcOTnxIPKbxVQcFMoRcl00uvF3x9TN');

const StripeContext = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeContext;
