// ModalComponent.jsx
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import classes from './OptionsModal.module.css';
// import CustomComponent from './CustomComponent';
import FieldOptionsBuilder from '../FieldOptionsBuilder/FieldOptionsBuilder';
import FieldOptionsSelector from '../FieldOptionsSelector/FieldOptionsSelector';

Modal.setAppElement('#root'); // Set the root element for accessibility

const ModalComponent = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [displayChoice, setDisplayChoice] = useState(true);
  const [selectFromExistingOptions, setSelectFromExistingOptions] = useState(false);
  const [createNewOptions, setCreateNewOption] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDisplayChoice(true);
    setSelectFromExistingOptions(false);
    setCreateNewOption(false);

  };

  const handleOptionSelection = (type) => {
    console.log("Check selected Option here: ", type);
    if(type==='existing'){
      setSelectFromExistingOptions(true);
      setCreateNewOption(false);
    }
    else{
      setSelectFromExistingOptions(false);
      setCreateNewOption(true);
    }
    setDisplayChoice(false);
    console.log("createNewOptions: ",createNewOptions);
    console.log("selectFromExistingOptions: ", selectFromExistingOptions);
    console.log("displayChoice: ", displayChoice);
  };

  const submitOption = (option) => {
    props.addOptionsIntoField(option);
  }

  return (
    <div index = {props.index}>
      <button className={classes.Button} onClick={openModal}>Add Options</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <button onClick={closeModal} style={{ float: 'right', margin: '5px' }}>×</button>
        {displayChoice  && 
        <div className={classes.DisplayButtonContainer}>
        <button className = {classes.Button} name={'existing'} onClick={(e)=>handleOptionSelection(e.target.name)}>Select From Options</button>
        <button className = {classes.Button} name={'new'} onClick={(e)=>handleOptionSelection(e.target.name)}>Create New Options</button>
        </div>
        }
        {createNewOptions &&  <FieldOptionsBuilder />}
        {selectFromExistingOptions && <FieldOptionsSelector submitOption = {submitOption}/>}
      </Modal>
    </div>
  );
};

export default ModalComponent;
