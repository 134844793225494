import React, { useState, useEffect } from 'react';
import {
  Box, Button, IconButton, Typography, Container, Toolbar, TextField, InputAdornment,
  Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import SectionBuilder from '../FormBuilder/SectionBuilder/SectionBuilder';
import CircularLoader from '../Loader/Loader';
import apiGatewayService from '../../common/services/apiGatewayService';
import utilityFunctions from '../../common/services/utilityFunctions';
import localStorageService from '../../common/services/localStorage.service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Sections = () => {
  const [sections, setSections] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false); // New state for view functionality
  const [viewIndex, setIsViewIndex] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [filterSectionName, setFilterSectionName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);

  const handleClose = async () => {
    setAllFalse();
    await getAllSections();
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setIsView(false); // Reset view state
    setIsViewIndex(null);
  };

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getSectionsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          return a.sectionName.localeCompare(b.sectionName);
        });
        setSections(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllSections = async () => {
    try {
      const response = await apiGatewayService.getSectionsData();
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => a.sectionName.localeCompare(b.sectionName));
      setSections(dataWithUpdatedKeyAndContent);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
    setIsLoading(false);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
  };

  const handleEdit = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    await getSectionById(filteredSections[index].sectionId);
    setIsEdit(true);
  };

  const handleView = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    await getSectionById(filteredSections[index].sectionId);
    setIsView(true); // Set view state to true
  };

  const handleDelete = async (index) => {
    try {
      const postData = { sectionNames: [filteredSections[index].sectionName] };
      await apiGatewayService.deleteSectionsData(postData);
      setSections(sections.filter((_, idx) => idx !== index));
    } catch (error) {
      console.error('Error deleting section:', error);
      alert('Section cannot be deleted');
    }
  };

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getSectionsDataById(sectionId);
      setCurrentSection(response.data);
    } catch (error) {
      console.error('Error getting section:', error);
    }
    setIsLoading(false);
  };

  const filteredSections = sections.filter(section =>
    section.sectionName.toLowerCase().includes(filterSectionName.toLowerCase())
  );

  return (
    <Container sx={{ padding: '0px', margin: 0, minWidth: '95%' }}>
      <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white' }}>
        <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
          <ViewListIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Sections
        </Typography>
        {(isEdit || isAddNew || isView) && 
          <Button sx={{
            width: '100px',
            marginRight: '-10px',
            ":hover": {
              backgroundColor: '#F0F4F4',
              color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon />} onClick={handleClose} disabled={isOnlyReadAccess}>
            Back
          </Button>}
        {(!isEdit && !isAddNew && !isView) &&
          <div>
            <Button sx={{
              marginRight: '10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAccess}>
              Add New
            </Button>
            <Button sx={{
              marginRight: '-10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }}
              color="inherit" startIcon={<ImportExportIcon />} disabled={isOnlyReadAccess}>
              Import
            </Button>
          </div>
        }
      </Toolbar>

      {isLoading && <CircularLoader />}

      {!isLoading && !isEdit && !isAddNew && !isView &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Section Name"
                    value={filterSectionName}
                    onChange={(e) => setFilterSectionName(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSections.map((section, index) => (
                <TableRow key={index} sx={{ height: 40 }}>
                  <TableCell sx={{ height: 40, padding: 1, paddingLeft: 2 }}>{section.sectionName}</TableCell>
                  <TableCell align="right" sx={{ height: 40, padding: 1 }}>
                    <IconButton aria-label="view" onClick={() => handleView(index)} disabled={isOnlyReadAccess}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

      {isAddNew && <SectionBuilder section={{}} handleClose={handleClose} />}
      {isEdit && <SectionBuilder section={currentSection} handleClose={handleClose} isEdit={true} sectionId={filteredSections[viewIndex]?.sectionId || null} />}
      {isView && <SectionBuilder section={currentSection} handleClose={handleClose} isView={true} sectionId={filteredSections[viewIndex]?.sectionId || null} />}
    </Container>
  );
};

export default Sections;