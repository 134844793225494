// These are the libraries we have installed in this project
// npm install @mui/icons-material
// npm install @mui/material @emotion/react @emotion/styled

// aws --profile freeform-dev s3 sync build/ s3://app.freeform.io

export const AWS_API = 'https://zspe1uaat0.execute-api.ap-southeast-2.amazonaws.com';
export const region = 'ap-southeast-2';
export const userPoolId = 'ap-southeast-2_YgNTdgNFB';
export const userPoolClientId = 'pavtghvrjh6ljcuu5svdtkmph';
export const stripePublishKey = 'pk_test_51PfvWERxkGGIIuhGxDBnr0eRkF8raQTghRdPpKaM0CLKinPP7U1fcCEnn3kS8ivb0lTHnE0NSMwbKwuakRPz1r0x00lHwHmvI7';