import React, { useState, useEffect } from 'react';
import classes from './SectionsSelector.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import { CircularProgress, TextField, MenuItem, Select, Button, Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SectionsSelector = ({ isOpen, handleClose, submitOption }) => {
  const [availableSections, setAvailableSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSectionDisplayName, setSelectedSectionDisplayName] = useState('');
  const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const selectOption = availableSections.find((option) => option.sectionName === event.target.value);
    setSelectedSection(selectOption);
    setSelectedSectionDisplayName(selectOption?.title || '');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredSections = availableSections.filter((option) =>
    option.sectionName.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSubmitOption = async () => {
    const section = await getSectionById(selectedSection.sectionName);
    submitOption(section);
    handleClose();
  };

  useEffect(() => {
    const getSections = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getSectionsData();
        const data = response.data;
        setAvailableSections(data);
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
      setIsLoading(false);
    };
    getSections();
  }, []);

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response =  await apiGatewayService.getSectionsDataById(sectionId);
      console.log('Get section by Id response: ', response);
      setIsLoading(false);
      return response.data;
      // setCurrentSection(response.data);
    } catch(error) {
      setIsLoading(false);
      console.log('Error in getting sections: ', error);
    }
  };


  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="sections-selector-title" aria-describedby="sections-selector-description">
      <Box
        className={classes.ModalContainer}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px', // Adjust the width as needed
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <h1>Select Section</h1>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>
        ) : (
          <div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Filter Section"
              value={filter}
              onChange={handleFilterChange}
              sx={{
                marginBottom: '16px',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />
            <Select
              fullWidth
              value={selectedSectionDisplayName}
              onChange={handleChange}
              displayEmpty
              sx={{
                marginBottom: '16px',
                height: '40px',
                backgroundColor: 'white',
              }}
            >
              <MenuItem value="" disabled>
                <em>Select an option</em>
              </MenuItem>
              {filteredSections.map((option, index) => (
                <MenuItem key={index} value={option.sectionName}>
                  {option.sectionName}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmitOption}
                sx={{
                  backgroundColor: 'var(--button-color)',
                  color: 'white',
                  fontWeight: 'normal',
                  textTransform: 'none',
                  ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default SectionsSelector;